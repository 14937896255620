/* here we are defining the common theme of the application which is used for toqio component */

export const theme = {color: {
  avatar: {
    icon: {
      background: '#F4F4F4',
      icon: '#1E1E1E'
    },
    initials: {
      background: '#477390',
      font: '#FFFFFF'
    }
  },
  badge: {
    background: '#F44336'
  },
  bullet_list: {
    icon: '#00CECE'
  },
  chip: {
    miscellany: {
      bermillion: {
        background: '#EB4E66',
        font: '#FFFFFF'
      },
      brownie: {
        background: '#A66A24',
        font: '#FFFFFF'
      },
      grass: {
        background: '#15D18D',
        font: '#FFFFFF'
      },
      grey_blue: {
        background: '#6A8CAD',
        font: '#FFFFFF'
      },
      orange: {
        background: '#EE7C13',
        font: '#FFFFFF'
      },
      purple: {
        background: '#8463EE',
        font: '#FFFFFF'
      },
      sky: {
        background: '#1CA3E6',
        font: '#FFFFFF'
      }
    },
    status: {
      danger: {
        background: '#FDECEA',
        font: '#F44336'
      },
      info: {
        background: '#EAF8FD',
        font: '#15B2EB'
      },
      neutral: {
        background: '#DADADA',
        font: '#696969'
      },
      success: {
        background: '#EDF7ED',
        font: '#4CAF50'
      },
      warning: {
        background: '#FFF4E5',
        font: '#FF9800'
      }
    }
  },
  containers: {
    bordered: {
      background: 'transparent',
      border: '#DADADA'
    },
    shadowed: {
      background: '#FFFFFF'
    }
  },
  contextual_modal_box: {
    background: '#FFFFFF',
    critical: {
      links: '#F44336'
    },
    default: {
      links: '#89cce2'
    }
  },
  feedback_notification: {
    error: {
      background: '#FDECEA',
      font: '#1E1E1E',
      icon: '#F44336'
    },
    info: {
      background: '#EAF8FD',
      font: '#1E1E1E',
      icon: '#15B2EB'
    },
    success: {
      background: '#EDF7ED',
      font: '#1E1E1E',
      icon: '#4CAF50'
    },
    warning: {
      background: '#FFF4E5',
      font: '#1E1E1E',
      icon: '#FF9800'
    }
  },
  forms: {
    default: {
      background: '#F2F5F7',
      border: '#F2F5F7',
      focus: {
        background: '#F2F5F7',
        border: '#83A1B4',
        innericons_and_selected: '#89cce2',
        innericons_background: '#E7EDF1',
        placeholder_font: '#8F8F8F',
        primary_font: '#1E1E1E',
        secondary_font: '#696969',
        unselected: '#83A1B4'
      },
      innericons_and_selected: '#89cce2',
      innericons_background: '#E7EDF1',
      placeholder_font: '#8F8F8F',
      primary_font: '#1E1E1E',
      secondary_font: '#696969',
      unselected: '#83A1B4'
    },
    error: {
      background: '#FDECEA',
      border: '#FDECEA',
      focus: {
        background: '#FDECEA',
        border: '#F44336',
        innericons_and_selected: '#89cce2',
        innericons_background: '#E7EDF1',
        placeholder_font: '#8F8F8F',
        primary_font: '#1E1E1E',
        secondary_font: '#696969',
        unselected: '#83A1B4'
      },
      innericons_and_selected: '#89cce2',
      innericons_background: '#E7EDF1',
      placeholder_font: '#8F8F8F',
      primary_font: '#1E1E1E',
      secondary_font: '#696969',
      unselected: '#83A1B4'
    }
  },
  general: {
    background: '#FFFFFF',
    dividers: '#DADADA',
    font: {
      primary: '#1E1E1E',
      quaternary: '#8F8F8F',
      secondary: '#434343',
      tertiary: '#696969'
    },
    scroll_bar: '#89cce2'
  },
  group_separator: {
    background: '#F4F4F4',
    font: '#696969'
  },
  help: {
    error: {
      font: '#F44336',
      icon: '#F44336'
    },
    info: {
      font: '#696969',
      icon: '#15B2EB'
    },
    link: {
      font: '#89cce2',
      icon: '#15B2EB'
    },
    warning: {
      font: '#696969',
      icon: '#FF9800'
    }
  },
  icon_button: {
    accent: {
      active: {
        background: '#F2F5F7',
        border: '#F2F5F7',
        icon: '#F22AA2'
      },
      default: {
        background: '#F22AA2',
        border: '#F22AA2',
        icon: '#FFFFFF'
      },
      focus: {
        background: '#FFFFFF',
        border: '#F22AA2',
        icon: '#F22AA2'
      },
      hover: {
        background: '#F2F5F7',
        border: '#F2F5F7',
        icon: '#F22AA2'
      }
    },
    critical: {
      active: {
        background: '#F44336',
        border: '#F44336',
        icon: '#FFFFFF'
      },
      default: {
        background: '#FDECEA',
        border: '#FDECEA',
        icon: '#F44336'
      },
      focus: {
        background: '#FFFFFF',
        border: '#F44336',
        icon: '#F44336'
      },
      hover: {
        background: '#F44336',
        border: '#F44336',
        icon: '#FFFFFF'
      }
    },
    primary: {
      active: {
        background: '#477390',
        border: '#477390',
        icon: '#FFFFFF'
      },
      default: {
        background: '#89cce2',
        border: '#89cce2',
        icon: '#FFFFFF'
      },
      focus: {
        background: '#FFFFFF',
        border: '#89cce2',
        icon: '#89cce2'
      },
      hover: {
        background: '#477390',
        border: '#477390',
        icon: '#FFFFFF'
      }
    },
    secondary: {
      active: {
        background: '#E7EDF1',
        border: '#E7EDF1',
        icon: '#89cce2'
      },
      default: {
        background: '#F2F5F7',
        border: '#F2F5F7',
        icon: '#89cce2'
      },
      focus: {
        background: '#FFFFFF',
        border: '#89cce2',
        icon: '#89cce2'
      },
      hover: {
        background: '#E7EDF1',
        border: '#E7EDF1',
        icon: '#89cce2'
      }
    }
  },
  link: {
    critical: '#F44336',
    default: '#89cce2'
  },
  modal_dialog: {
    background: '#FFFFFF',
    overlay_background: '#89cce2'
  },
  navigation_row: {
    arrow: '#89cce2',
    background: '#F2F5F7',
    font: '#1E1E1E',
    icon: '#1E1E1E'
  },
  progress_and_loading: {
    background: '#F4F4F4',
    main: '#00CECE'
  },
  selection_box: {
    selected: {
      background: '#FFFFFF',
      border: '#477390',
      font_primary: '#1E1E1E',
      font_secondary: '#696969',
      icon: '#89cce2',
      select_all_background: '#E7EDF1',
      select_all_icon: '#89cce2'
    },
    unselected: {
      background: '#FFFFFF',
      border: '#E7EDF1',
      font_primary: '#1E1E1E',
      font_secondary: '#696969',
      select_all_background: '#E7EDF1',
      select_all_icon: '#89cce2'
    }
  },
  sidebar: {
    active_section_badge: '#00CECE',
    background: '#FFFFFF',
    divider: '#DADADA',
    font_active_section: '#1E1E1E',
    font_legal: '#8F8F8F',
    font_non_active_section: '#8F8F8F'
  },
  standard_button: {
    accent: {
      active: {
        background: '#F2F5F7',
        border: '#F2F5F7',
        font: '#F22AA2'
      },
      default: {
        background: '#F22AA2',
        border: '#F22AA2',
        font: '#FFFFFF'
      },
      focus: {
        background: '#FFFFFF',
        border: '#F22AA2',
        font: '#F22AA2'
      },
      hover: {
        background: '#F2F5F7',
        border: '#F2F5F7',
        font: '#F22AA2'
      }
    },
    critical: {
      active: {
        background: '#F44336',
        border: '#F44336',
        font: '#FFFFFF'
      },
      default: {
        background: '#FDECEA',
        border: '#FDECEA',
        font: '#F44336'
      },
      focus: {
        background: '#FFFFFF',
        border: '#F44336',
        font: '#F44336'
      },
      hover: {
        background: '#F44336',
        border: '#F44336',
        font: '#FFFFFF'
      }
    },
    primary: {
      active: {
        background: '#477390',
        border: '#477390',
        font: '#FFFFFF'
      },
      default: {
        background: '#89cce2', //default button color
        border: '#89cce2',
        font: '#FFFFFF'
      },
      focus: {
        background: '#FFFFFF',
        border: '#89cce2',
        font: '#89cce2'
      },
      hover: {
        background: '#477390',
        border: '#477390',
        font: '#FFFFFF'
      }
    },
    secondary: {
      active: {
        background: '#E7EDF1',
        border: '#E7EDF1',
        font: '#89cce2'
      },
      default: {
        background: '#F2F5F7',
        border: '#F2F5F7',
        font: '#89cce2'
      },
      focus: {
        background: '#FFFFFF',
        border: '#89cce2',
        font: '#89cce2'
      },
      hover: {
        background: '#E7EDF1',
        border: '#E7EDF1',
        font: '#89cce2'
      }
    }
  },
  table_row: {
    positive_background: '#EDF7ED'
  },
  tabs: {
    selected_tab: {
      background: 'transparent',
      font: '#1E1E1E',
      selector: '#00CECE'
    },
    unselected_tab: {
      background: 'transparent',
      font: '#1E1E1E'
    }
  },
  tag: {
    dark: {
      background: '#477390',
      font: '#FFFFFF'
    },
    light: {
      background: '#F2F5F7',
      font: '#89cce2'
    }
  },
  toast: {
    success: {
      background: '#4CAF50',
      font: '#FFFFFF'
    },
    wrong: {
      background: '#F44336',
      font: '#FFFFFF'
    }
  },
  tooltip: {
    background: '#89cce2',
    font: '#FFFFFF'
  },
  topbar: {
    background: 'transparent',
    expandable_background: '#FFFFFF'
  }
},
radii: {
  radius12: 'border-radius: 12px;',
  radius4: 'border-radius: 4px;',
  radius8: 'border-radius: 8px;'
},
shadows: {
  '1': 'box-shadow: rgba(0,0,0,0.2) 0px 1px 3px 0px;',
  '2': 'box-shadow: rgba(0,0,0,0.2) 0px 4px 8px -2px;',
  '3': 'box-shadow: rgba(0,0,0,0.15) 0px 8px 16px -4px;',
  '4': 'box-shadow: rgba(0,0,0,0.15) 0px 12px 24px -4px;'
},
typography: {
  fontFamily: 'Inter,sans-serif'
}
}