/* This component is used for FIGMA SCREEN 34,35,36 and 37. for showing the details on clicking of learn more item */

import React, { useState } from "react";
import {
  Button,
  Container,
} from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import CarbonCalculator from "../CarbonCalculator";
import { SlArrowRight } from "react-icons/sl";
import CO2Footer from "../../Components/CO2Footer";
import { PRIMARY_COLOR } from "../../Constants/color";
import OurPartner from "../../Components/OurPartner";
import { GiPalmTree } from "react-icons/gi";
import { BsCloudy } from "react-icons/bs";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

const Details = () => {
  const navigate = useNavigate();

  const location = useParams();
  const [loading, setLoading] = useState(false);

/* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setLoading(true);
    if (location) {
      setTimeout(() => {
        setLoading(false);
        window.scrollTo(0, 0);
      }, 10);
    }
  }, [location?.id]);


/* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const Co2Item = () => {
    const topCard = {
      img: require("../../assets/co2.png"),
    };

    return (
      <div>
        <Header title={"Learn More"} hideBack={() => navigate("/")} />
        <Container type="shadow" className={styles["container"]}>
          <img src={topCard.img} className={styles["img"]} />
          <div className="p-20">
            <p className={styles["title"]}>
              What is CO<sub>2</sub>
            </p>
            <p className={styles["desc"]}>
              CO<sub>2</sub>, or carbon dioxide, is the most significant
              greenhouse gas in the Earth's atmosphere.
              <br /> <br />
              Greenhouse gases live in the Earth's atmosphere and trap heat.
              This means the gases let sunlight pass through the atmosphere but
              they prevent the heat from escaping. When gases in the Earth's
              atmosphere trap the sun's heat, this process is called the
              greenhouse effect.
            </p>
            <p className={styles["subtitle"]}>Fossil fuels</p>
            <p className={styles["desc"]}>
              The burning of fossil fuels, coal, oil and natural gas, along with
              deforestation, contribute to an increase of CO<sub>2</sub> in the
              atmosphere that leads to climate change.
              <br /> <br />
              When fuels are burned to make gasoline, for example, carbon
              emissions are produced. This carbon, or CO<sub>2</sub>, is emitted
              in the atmosphere and traps heat. When the Earth’s temperature
              rises due an abundance of CO<sub>2</sub>, it’s called global
              warming.{" "}
            </p>

            <img
              className={styles["sub-img"]}
              src={require("../../assets/earth_melt.png")}
            />
            <br />
            <p className={styles["title"]}>
              How does CO<sub>2</sub> affect the climate?
            </p>
            <p className={styles["desc"]}>
              The overabundance of CO<sub>2</sub> and how it affects the planet
              is studied closely by scientists from NASA Global Climate Change.
              Their team has come to the scientific consensus that the increase
              in CO<sub>2</sub> in the atmosphere is warming the Earth’s
              climate. High CO<sub>2</sub> levels result from three main
              sources: the use of fossil fuels, generation of energy through
              non-renewable channels and polluting human activities.
            </p>
            <p className={styles["title"]}>Higher temperatures</p>
            <p className={styles["desc"]}>
              Increased CO<sub>2</sub> in the environment has now tipped the
              Earth’s energy budget out of balance, trapping additional heat and
              raising the Earth’s average temperature.
              <br /> <br />
              As the Earth’s temperature rises, our climate changes. Signs of a
              warmer climate include loss of glacial ice from melting, a higher
              sea level and longer, more intense heat waves. In your own
              neighborhood, you may notice rivers and lakes breaking up ice
              earlier, trees flowering sooner or more intense and frequent
              weather events like droughts, hurricanes and floods.
            </p>
            <p>
              {" "}
              <span className="font-16" style={{ fontFamily: "Inter-SemiBold" }}>
                Source:{" "}
              </span>
              <span className="font-16" style={{ color: PRIMARY_COLOR }}>
                The World Resources Institute{" "}
              </span>{" "}
              and{" "}
              <span className="font-16" style={{ color: PRIMARY_COLOR }}>
                Conservation International
              </span>
            </p>
            <div className="divider"></div>
            <CO2Footer
              desc="What is the Carbon Calculator experience?"
              onClick={() => navigate("/learn-more/details/2")}
            />
            <CO2Footer
              style={{ marginTop: 20 }}
              hideTitle={true}
              desc="Back to Dashboard"
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </Container>
      </div>
    );
  };

  const Co2CareItem = () => {
    const topCard = {
      img: require("../../assets/care_co2.png"),
    };

    return (
      <div>
        <Header title={"Learn More"} hideBack={() => navigate("/")} />
        <Container type="shadow" className={styles["container"]}>
          <img src={topCard.img} className={styles["img"]} />
          <div className="p-20">
            <p className={styles["title"]}>
              Why should I care about CO<sub>2</sub>?
            </p>
            <p className={styles["desc"]}>
              The warming of our planet is a something that affects us all,
              worldwide, as it creates an unstable atmosphere and leads to
              extreme weather events.
              <br /> <br />
              Take water, for example. Even a small increase in global
              temperatures can destabilize the water cycle and make water
              scarcity worse. Climate change also affects rainfall patterns
              making flooding and drought more common and intense.
            </p>
            <p className={styles["subtitle"]}>Health</p>
            <p className={styles["desc"]}>
              Our health is also vulnerable to global warming. The changing
              environment is expected to cause poor air quality, more heat
              stress, and an increase in diseases that are waterborne or
              transmitted by insects and rodents.
            </p>
            <p className={styles["subtitle"]}>Ecosystems</p>
            <p className={styles["desc"]}>
              In addition, the environment is what houses and helps our
              ecosystems grow and thrive to keep plants, crops and animals
              healthy. The ecosystems that make up our environment are deeply
              connected and are all affected by climate change.
            </p>
            <p className="font-16" style={{ lineHeight: 1.5 }}>
              {" "}
              <span style={{ fontFamily: "Inter-SemiBold" }}>
                Source:{" "}
              </span>
              <span style={{ color: PRIMARY_COLOR }}>
                The World Resources Institute{" "}
              </span>{" "}
              and{" "}
              <span style={{ color: PRIMARY_COLOR }}>
                Conservation International
              </span>
            </p>
            <div className="divider"></div>
            <CO2Footer
              desc="How do I compare to others?"
              onClick={() => navigate("/learn-more/details/4")}
            />
            <CO2Footer
              style={{ marginTop: 20 }}
              hideTitle={true}
              desc="Back to Dashboard"
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </Container>
      </div>
    );
  };

  const CompareItem = () => {
    const topCard = {
      img: require("../../assets/compare.png"),
    };
    const getFlagEmoji = (countryCode) =>
      String.fromCodePoint(
        ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
      );

    const countryData = [
      {
        img: "AU",
        value: "1,281kg",
        progress: 95,
        name: "Australia",
      },
      {
        img: "US",
        value: "1,187kg",
        progress: 85,
        name: "United States",
      },
      {
        img: "CA",
        value: "1,183kg",
        progress: 80,
        name: "Canada",
      },
      {
        img: "JP",
        value: "679kg",
        progress: 70,
        name: "Japan",
      },
      {
        img: "DE",
        value: "641kg",
        progress: 65,
        name: "Germany",
      },
      {
        img: "ZA",
        value: "635kg",
        progress: 60,
        name: "South Africa",
      },
      {
        img: "CN",
        value: "618kg",
        progress: 50,
        name: "China",
      },
      {
        img: "GB",
        value: "404kg",
        progress: 40,
        name: "United Kingdom",
      },
      {
        img: "SE",
        value: "319kg",
        progress: 30,
        name: "Sweden",
      },
      {
        img: "MX",
        value: "231kg",
        progress: 20,
        name: "Mexico",
      },
      {
        img: "BR",
        value: "183kg",
        progress: 15,
        name: "Brazil",
      },
      {
        img: "IN",
        value: "148kg",
        progress: 10,
        name: "India",
      },
    ];

    return (
      <div>
        <Header title={"Learn More"} hideBack={() => navigate("/")} />
        <Container type="shadow" className={styles["container"]}>
          <img src={topCard.img} className={styles["img"]} />
          <div className="p-20">
            <p className={styles["title"]}>How do I compare to others?</p>
            <p className={styles["desc"]}>
              Curious to see how your carbon footprint compares to others in
              your country and around the world?
            </p>
            <p className={styles["subtitle"]}>Monthly emissions per person</p>
            <div>
              {countryData?.map((itm) => (
                <div
                  className="row-center mt-1"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="font-14" style={{ textAlign: "right",width:120 }}>
                    {itm.name}
                  </div>
                 
                  <ReactCountryFlag
                    countryCode={itm.img}
                    style={{
                      fontSize: "1em",
                      lineHeight: "2em",
                      marginLeft:'4px',
                      marginRight:'4px'
                    }}
                    svg
                  />
                  <div style={{ width: "80%",display:'flex',alignItems:'center' }}>
                    <div
                      style={{
                        width: `${itm.progress}%`,
                        height: 14,
                        borderEndEndRadius: 3,
                        backgroundColor: "#BCBCBC",
                        borderTopRightRadius: 3,
                      }}
                    ></div>
                    <div className="font-14" style={{  marginLeft:5 }}>{itm.value}</div>
                  </div>
                  
                </div>
              ))}
            </div>
            <div>
              <div className={styles["sub-desc"]}>
                kg = kilograms of CO<sub>2</sub> per person
              </div>
            </div>

            <p className={styles["title"]}>Understanding the data?</p>
            <p className={styles["desc"]}>
              The graph shows you how many kilograms of CO<sub>2</sub> per
              person are emitted in each country, per month.
              <br />
              <br />
              Countries at the top of the chart, such as Australia, have high
              total emissions because they are less populated and have greater
              than average CO<sub>2</sub> emissions. Greater than average per
              person emissions are due in large part to a country relying
              heavily on fossil fuels for electricity which emits CO<sub>2</sub>
              .
              <br />
              <br />
              In contrast, a country toward the bottom of the chart, like
              Sweden, is heavily populated but has low per person emissions as a
              result of their energy sources. For example, a much higher share
              of Sweden’s electricity is produced from nuclear and renewable
              sources, as opposed to fossil fuels, creating lower overall CO
              <sub>2</sub>
              levels per person.
            </p>
            <p className="font-16" style={{ lineHeight: 1.5 }}>
              {" "}
              <span style={{ fontFamily: "Inter-SemiBold"}}>
                Source:{" "}
              </span>
              <span style={{ color: PRIMARY_COLOR}}>
                The World Resources Institute{" "}
              </span>{" "}
              and{" "}
              <span style={{ color: PRIMARY_COLOR}}>
                Conservation International
              </span>
            </p>
            <div className="divider"></div>
            <CO2Footer
              style={{ marginTop: 20 }}
              desc="The importance of trees"
              onClick={() => navigate("/learn-more/details/5")}
            />
            <CO2Footer
              style={{ marginTop: 20 }}
              hideTitle={true}
              desc="Back to Dashboard"
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </Container>
      </div>
    );
  };

  const TreeItem = () => {
    const topCard = {
      img: require("../../assets/tree.png"),
    };

    return (
      <div>
        <Header title={"Learn More"} hideBack={() => navigate("/")} />
        <Container type="shadow" className={styles["container"]}>
          <img src={topCard.img} className={styles["img"]} />
          <div className="p-20">
            <p className={styles["title"]}>The importance of trees</p>
            <p className={styles["desc"]}>
              Restoring the earth’s forests is considered the most effective
              method of addressing climate change. Trees are sometimes called
              the “lungs of the earth” because they store CO<sub>2</sub> by
              absorbing pollutants through their leaves, trap and filter
              contaminants in the air, and produce oxygen.
              <br /> <br />
              In addition, trees stabilize the soil, give life to the world's
              animals and provide material for tools and shelter. It's critical
              that forests are sustainably managed across the globe through
              reforestation efforts like the Priceless Planet Coalition.
            </p>
            <div
              style={{
                width: "70%",
                margin: "20px auto",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 30,
                  backgroundColor: "#F0F0F0",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {/* <img
                  src={require("../../assets/onboarding/cloud.png")}
                  style={{ width: 30, height: 30 }}
                /> */}

                <BsCloudy size={30} style={{ marginBottom: 5 }} />
              </div>
              <div className="row-center">
                <SlArrowRight className="font-18" />
                <SlArrowRight className="font-18" />
                <SlArrowRight className="font-18" />
                <SlArrowRight className="font-18" />
                <SlArrowRight className="font-18" />
              </div>
              <div
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 30,
                  backgroundColor: "#F0F0F0",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {/* <img
                  src={require("../../assets/onboarding/tree.png")}
                  style={{ width: 35, height: 40 }}
                /> */}
                <GiPalmTree size={30} />
              </div>
            </div>
            <div className="font-12"
              style={{
                width: "65%",
                margin: "-10px auto",
                alignItems: "center",
             
              }}
            >
              <p>
                kg CO<sub>2</sub>
              </p>
            </div>
            <br />
            <p className={styles["title"]}>How do trees capture carbon?</p>
            <p className={styles["desc"]}>
              During one year, a mature tree, defined as five years or older,
              captures more than 48 pounds of CO<sub>2</sub> from the atmosphere
              and releases oxygen in exchange. As the largest plants on the
              planet, trees are also the longest living species on earth. They
              give us a link between the past, present and future.
              <br /> <br />
              Trees continue to capture CO<sub>2</sub> from the air throughout
              their life span. Statistics from arborists, professionals who care
              for trees, say a typical hardwood tree can capture approximately 1
              ton of carbon dioxide by the time it reaches 40 years of age.
            </p>
            <br />
            <p className={styles["title"]}>What is reforestation work?</p>
            <p className={styles["desc"]}>
              The Priceless Planet Coalition, Mastercard’s environmental
              restoration platform, began with three projects in 2020.
              <br />
              <br />
              By early 2022, 15 projects were added totaling 18 worldwide.
              <br /> <br />
              The coalition’s goal is to plant 100 million trees with support
              from its 100+ global partners, your bank and you.
            </p>

            <img
              src={require("../../assets/earth.webp")}
              className={styles["sub-img"]}
            />
            <div className="row-center">
              <Button
                onClick={() => navigate("/donation-option")}
                className="solid-btn"
              >
                Donate to plant trees
              </Button>
            </div>
            <br />
            <p className={styles["title"]}>
              Where are the reforestation projects located?
            </p>
            <p className={styles["desc"]}>
              In 2020, the three projects were located in Brazil (the Amazon
              Rainforest), Australia and Kenya. Now, projects are running in:
              the Asia-Pacific region, Latin America, Europe, the Middle East,
              Africa and North America.
            </p>
            {/* <div
              className="row-center"
              style={{ justifyContent: "space-between" }}
            >
              <img
                src={require("../../assets/logo1.png")}
                style={{ width: "40%", height: 50 }}
              />
              <img
                src={require("../../assets/logo2.png")}
                style={{ width: "40%", height: 50 }}
              />
            </div> */}
            <div className="w-100">
              <OurPartner isTitle={true} />
            </div>
            <br />

            <p className={styles["desc"]}>
              Guided by Conservation International and World Resources
              Institute, the coalition employs rigorous science-based best
              practices for our forest restoration projects, their
              implementation and monitoring.
            </p>
            <p className={styles["subtitle"]}>How trees help</p>
            <p className={styles["desc"]}>
              If you donate to have a tree planted, it will help the
              reforestation effort in one of the now 18 global sites of the
              Priceless Planet Coalition.
              <br /> <br />
              Reforestation methods include planting of seedlings, called direct
              seeding, and natural regeneration.
            </p>
            <p className="font-16" style={{ lineHeight: 1.5 }}>
              {" "}
              <span style={{ fontFamily: "Inter-SemiBold" }}>
                Source:{" "}
              </span>
              <span style={{ color: PRIMARY_COLOR }}>
                The Priceless Planet Coalition
              </span>
            </p>
            <div className="divider"></div>
            <CO2Footer
              style={{ marginTop: 20 }}
              hideTitle={true}
              desc="Back to Dashboard"
              onClick={() => navigate("/dashboard")}
            />
           
          </div>
        </Container>
      </div>
    );
  };
  return (
    <>
      {!loading ? (
        <div className="res-container">
          {location.id === "1" && <Co2Item />}
          {location.id === "2" && (
            <div>
              <Header title={"Learn More"} hideBack={() => navigate("/")} />
              <Container
                type="shadow"
                className={styles["container"]}
              >
                <CarbonCalculator hideButton={true} />
                <div className="p-20">
                  <CO2Footer
                    style={{ marginTop: 0 }}
                    desc="Why should I care about CO<sub>2</sub>?"
                    onClick={() => navigate("/learn-more/details/3")}
                  />
                   <CO2Footer
              style={{ marginTop: 20 }}
              hideTitle={true}
              desc="Back to Dashboard"
              onClick={() => navigate("/dashboard")}
            />
                </div>
           
              </Container>
            </div>
          )}
          {location.id === "3" && <Co2CareItem />}

          {location.id === "4" && <CompareItem />}
          {location.id === "5" && <TreeItem />}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Details;
