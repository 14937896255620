/* this is the common function which is used to return the react-icons based on icon name */


import { AiOutlineCar } from "react-icons/ai";
import { VscHome } from "react-icons/vsc";
import { ImSpoonKnife } from "react-icons/im";
import { HiOutlineShoppingBag } from "react-icons/hi";
import {  AiFillCar,AiFillTool } from "react-icons/ai";
import { TbHeartPlus,TbTemperature } from "react-icons/tb";
import { IoGameControllerOutline,IoLeafSharp,IoLibrary } from "react-icons/io5";
import { SlPlane } from "react-icons/sl";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import {BiBus} from 'react-icons/bi';
import{GiCarWheel,GiBarefoot,GiGreekTemple,GiTShirt,GiFarmer} from 'react-icons/gi';
import {MdOutlineDirectionsBike,MdAirplanemodeActive,MdBackpack,MdOutlineRecycling,MdOutlineBatteryStd,MdMonitor} from 'react-icons/md'
import {TbVaccineBottle} from 'react-icons/tb';
import {FaRedhat,FaPumpMedical,FaLightbulb,FaAppleAlt,FaSolarPanel} from 'react-icons/fa';
import {BsBagFill,BsLaptopFill} from 'react-icons/bs';
import {VscSearch} from 'react-icons/vsc';
import {RiCoinFill} from 'react-icons/ri';
import {GiTap,GiFruitTree,GiWashingMachine,GiCow,GiBee} from 'react-icons/gi';
import {IoLogoGameControllerB} from 'react-icons/io';
import {FiCheck} from 'react-icons/fi'
import { PRIMARY_COLOR } from "./color";

export const getIcon = (name='', color=PRIMARY_COLOR,size) => {
  const icons = {
    SlPlane: <SlPlane size={size} color={color} />,
    HiOutlineBuildingLibrary: (
      <HiOutlineBuildingLibrary size={size} color={color} />
    ),
    IoGameControllerOutline: (
      <IoGameControllerOutline size={size} color={color} />
    ),
    AiOutlineCar: <AiOutlineCar size={size} color={color} />,
    TbHeartPlus: <TbHeartPlus size={size} color={color} />,
    HiOutlineShoppingBag: <HiOutlineShoppingBag size={size} color={color} />,
    ImSpoonKnife: <ImSpoonKnife size={size} color={color} />,
    VscHome: <VscHome size={size} color={color} />,

    BiBus: <BiBus size={size} color={color} />,

    AiFillCar: <AiFillCar size={size} color={color} />,

    GiCarWheel: <GiCarWheel size={size} color={color} />,

    GiBarefoot: <GiBarefoot size={size} color={color} />,

    MdOutlineDirectionsBike: (
      <MdOutlineDirectionsBike size={size} color={color} />
    ),

    MdAirplanemodeActive: <MdAirplanemodeActive size={size} color={color} />,

    GiGreekTemple: <GiGreekTemple size={size} color={color} />,
    MdAirplanemodeActive: <MdAirplanemodeActive size={size} color={color} />,

    MdAirplanemodeActive: <MdAirplanemodeActive size={size} color={color} />,
    MdBackpack: <MdBackpack size={size} color={color} />,

    TbVaccineBottle: <TbVaccineBottle size={size} color={color} />,

    BsBagFill: <BsBagFill size={size} color={color} />,

    MdOutlineRecycling: <MdOutlineRecycling size={size} color={color} />,

    GiTShirt: <GiTShirt size={size} color={color} />,

    FaRedhat: <FaRedhat size={size} color={color} />,

    FaPumpMedical: <FaPumpMedical size={size} color={color} />,

    TbVaccineBottle: <TbVaccineBottle size={size} color={color} />,

    VscSearch: <VscSearch size={size} color={color} />,
    FaPumpMedical: <FaPumpMedical size={size} color={color} />,
    RiCoinFill: <RiCoinFill size={size} color={color} />,

    IoLeafSharp: <IoLeafSharp size={size} color={color} />,

    GiFarmer: <GiFarmer size={size} color={color} />,

    FaAppleAlt: <FaAppleAlt size={size} color={color} />,

    GiCow: <GiCow size={size} color={color} />,
    ImSpoonKnife: <ImSpoonKnife size={size} color={color} />,
    IoLogoGameControllerB: (
      <IoLogoGameControllerB size={size} color={color} />
    ),

    MdOutlineBatteryStd: <MdOutlineBatteryStd size={size} color={color} />,

    AiFillTool: <AiFillTool size={size} color={color} />,

    GiBee: <GiBee size={size} color={color} />,

    FiCheck: <FiCheck size={size} color={color} />,

    BsLaptopFill: <BsLaptopFill size={size} color={color} />,

    TbTemperature: <TbTemperature size={size} color={color} />,

    MdMonitor: <MdMonitor size={size} color={color} />,

    IoLibrary: <IoLibrary size={size} color={color} />,

    FaSolarPanel: <FaSolarPanel size={size} color={color} />,
    FaLightbulb: <FaLightbulb color={color} size={size} />,

    GiTap: <GiTap color={color} size={size} />,

    GiFruitTree: <GiFruitTree color={color} size={size} />,

    FaLightbulb: <FaLightbulb color={color} size={size} />,

    GiWashingMachine: <GiWashingMachine color={color} size={size} />,
  };
  return icons[name?.trim()];
};
