/* this component is used for common button component in the application */

import React,{useState} from "react";

const Button = ({onClick,style,className,children,loading}) => {



  return (
    <div onClick={onClick} style={style} className={className}>
       {children}
    </div>
  );
};

export default Button;
