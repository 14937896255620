/* this is the common function which is used to return thelocal images based on image name */

export const getImage = (name) => {
  const images = {
    SlPlane: require("../assets/icons/SlPlane.png"),
    HiOutlineBuildingLibrary: require("../assets/icons/HiOutlineBuildingLibrary.png"),
    IoGameControllerOutline: require("../assets/icons/IoGameControllerOutline.png"),
    AiOutlineCar: require("../assets/icons/AiOutlineCar.png"),
    TbHeartPlus: require("../assets/icons/TbHeartPlus.png"),
    HiOutlineShoppingBag: require("../assets/icons/HiOutlineShoppingBag.png"),
    ImSpoonKnife: require("../assets/icons/ImSpoonKnife.png"),
    VscHome: require("../assets/icons/VscHome.png"),

  
  };

  return images[name];
};
