/* this is the common component which is used to show 'powered by' in the footer of take action steps  */


import React from "react";
import { useNavigate } from "react-router-dom";

const PoweredBy = ({ style={} }) => {
  const navigate = useNavigate();

  return (

    <div style={{position:'absolute',maxWidth:600, width:'100%',justifyContent:'center',display:'flex',paddingBottom:20,...style}}>
        <div style={{color:'#282828',marginRight:5, fontFamily:'Inter-Medium'}}>Powered by</div>
        <img style={{width:30}} src={require('../assets/mastercard.png')} />
    </div>
  );
};

export default PoweredBy;
