import React from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Container } from "@toqio/toqio-web-components";

const SettingDetails = () => {
  const navigate = useNavigate();

  return (
    <div className="res-container">
      <Header title={"Carbon Calculator"} hideBack={() => navigate("/")} />

      <Container
        type="shadow"
        className="p-20"
        style={{ width: "100%", paddingTop: 10, margin: "20px auto" }}
      >
        <p className="font-16" style={{ lineHeight: 1.5 }}>
          By opting-out, you understand your carbon footprint data will no
          longer be processed. You can opt-in again at any time within the
          PartnerBank app.
        </p>
      </Container>
    </div>
  );
};

export default SettingDetails;
