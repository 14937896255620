/* This component is used for FIGMA SCREEN 42,43,44,45,46,47,48,49. to show the details of each quick tips item based on user selection  */

import React, { useState,useEffect } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container, Spinner,
} from "@toqio/toqio-web-components";
import styles from "./styles.module.css";

import { getRequest } from "../../Services/RequestServices";
import {API_TIP_CATEGORIES} from "../../Services/ApiConstant";
import { getIcon } from "../../Constants/icons";


const QuickTipsDetails = () => {
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);


  const params = useParams();

  const { id } = params;

  /* this function will use to call for getting the quick tips details or content based on selected category */
  useEffect(()=>{
    setLoading(true);
    getRequest("customer/"+id+'/'+API_TIP_CATEGORIES,true).then((res)=>{
      if(res.data){
        setData(res.data)

      }
      setLoading(false);
    })
  },[])

  /* it will call only once while loading the component and set sctoll postition to the top */

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0, 0)
    },10)
  

},[])

  return (
    <div className="res-container">
      <Header title={"Quick tips"} hideBack={() => navigate("/")} />
      <div  style={{ background: "#F8F8F8" }}>
        <Container
          type="shadow"
          style={{ width: "100%",maxWidth:600, paddingTop: 10, margin: "0px auto" }}
        >
{!loading ? (
          <>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            className="border-bottom mb-2 mt-2"
          >
            {getIcon(data?.mainCategoryIcon,data?.maincategoryColor,'35px')}
            <p className="font-20"
              style={{
                fontFamily: "Inter-SemiBold",
                
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              {data?.mainCategoryTitle}
            </p>
          </div>
          {data?.tip?.map((itm) => (
            <div className="row-start mt-2 w-100">
              {getIcon(itm.tipIcon,data?.maincategoryColor,'25px')}
              <div className="ml-2 border-bottom w-80">
                <div className={styles["title"]}>{itm?.tipName}</div>
                <p className={styles["desc"]} dangerouslySetInnerHTML={{ __html: itm.tipDescription }} />
              </div>
            </div>
          ))}
          </>

          ):(
               <div className="row-center w-100" style={{height:'50vh'}} >
               {" "}
               <Spinner />
             </div>
            )}
        </Container>
      </div>
    </div>
  );
};

export default QuickTipsDetails;
