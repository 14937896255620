/* this component is used to create the common header only for onboarding step */


import React,{useState} from "react";
import {MdOutlineArrowBackIosNew,MdClose} from 'react-icons/md'
import {GrClose} from 'react-icons/gr'
import { PRIMARY_COLOR } from "../Constants/color";

const OnboardHeader = ({hideClose,hideBack,style}) => {



  return (
    <div
      style={{
        // width: "100%",
        display: "flex",
        margin:'0px auto',
        justifyContent: "space-between",
        alignItems: "center",
        height:50,
        ...style

      }}
    >
      {hideBack && (
       <MdOutlineArrowBackIosNew className="pointer ml-1" onClick={()=>{console.log('caled');hideBack()}} size={'24px'} color={PRIMARY_COLOR}/>

      )}
       {hideClose && (
       <MdClose className="pointer mr-1" onClick={hideClose}  size={'30px'} color={PRIMARY_COLOR}/>

       )}
     
    </div>
  );
};

export default OnboardHeader;
