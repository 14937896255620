/* This component is used for FIGMA SCREEN 51. for showing the donation options */


import React, { useState, useEffect } from "react";
import { Button, Container, TextField } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { PRIMARY_COLOR } from "../../Constants/color";
import OurPartner from "../../Components/OurPartner";
import { GiPalmTree } from "react-icons/gi";
import { BsCloudy } from "react-icons/bs";
import { getCookie } from "../../cookieHandler";
import { postRequest } from "../../Services/RequestServices";
import { API_DONATE_OPTION } from "../../Services/ApiConstant";

const DonationOption = () => {
  const navigate = useNavigate();

  const amount = [2, 4, 8, 20];
  const footPrint = getCookie("footprint");

  const [selected, setSelected] = useState();

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const onDonate = () => {

    // postRequest(API_DONATE_OPTION,{value:''}).then((res)=>{
      navigate("/payment-method");
    // })
  }

  return (
    <div className="res-container">
      <Header title={"Take action"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
          }}
        >
          <OurPartner
            width={"25%"}
            className={"row-center w-100"}
            imageClass="m-5"
            isMasterCard={true}
            isTitle={true}
          />
        </div>
        <br />
        <p style={{ fontFamily: "Inter-SemiBold" }} className="font-18">
          Donation options
        </p>
        <div className="font-14" style={{ fontFamily: "Inter-Regular" }}>
          Your current footprint is:{" "}
          <span className="font-14" style={{ fontFamily: "Inter-SemiBold" }}>
            {footPrint} kg CO<sub>2</sub>
          </span>
        </div>
        <div className="row-between mt-1">
          {amount?.map((itm) => (
            <div
              onClick={() => setSelected(itm)}
              className="font-14"
              style={{
                backgroundColor: selected === itm ? PRIMARY_COLOR : "#fff",
                color: selected === itm ? "#fff" : "#000",
                justifyContent: "center",
                borderRadius: 2,
                fontFamily: "Inter-SemiBold",
                 
                display: "flex",
                alignItems: "center",
                width: 50,
                height: 30,
                border: "1px solid #BCBCBC",
              }}
            >
              ${itm}
            </div>
          ))}
        </div>
        <div style={{ position: "relative" }}>
          <TextField
            label=""
            onChange={function noRefCheck() {}}
            placeholder="Other amount ($2-100)"
            className={styles["text-filed"]}
          />
          <p
            style={{
              position: "absolute",
              top: 7,
              right: 14,
              fontFamily: "Inter-SemiBold",
            }}
          >
            USD
          </p>
        </div>

        <div>
          <div
            style={{
              width: "100%",
              margin: "20px auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                borderRadius: 25,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                boxShadow: "0px 0px 2.50617px 1.00247px rgba(0, 0, 0, 0.05)",
                backdropFilter: "blur(1.3625px)",
                width: 100,
                height: 100,
                borderRadius: "5.01235px",
              }}
            >
              <GiPalmTree size={30} />

              <div
              className="font-14"
                style={{
                   
                  marginTop: 4,
                  fontFamily: "Inter-SemiBold",
                }}
              >
                0 trees
              </div>
            </div>

            <div
              style={{
                borderRadius: 25,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                boxShadow: "0px 0px 2.50617px 1.00247px rgba(0, 0, 0, 0.05)",
                backdropFilter: "blur(1.3625px)",
                width: 100,
                height: 100,
                borderRadius: "5.01235px",
              }}
            >
              <BsCloudy size={30} />
              <div
              className="font-14"
                style={{
                   
                  marginTop: 4,
                  fontFamily: "Inter-SemiBold",
                }}
              >
                0 kg CO<sub>2</sub>*
              </div>
            </div>
          </div>
        </div>

        <p
        className="font-14"
          style={{
             
            textAlign: "center",
            fontFamily: "Inter-SemiBold",
          }}
        >
          100% of your donation goes directly to Conservation International.
        </p>
        <div className="row-center mt-2">
          <Button
            className={!selected ? "solid-btn disabled-btn" : "solid-btn"}
            onClick={() => onDonate()}
            disabled={!selected}
          >
            Continue
          </Button>
        </div>
        <p className="font-13" style={{ marginTop: 30, textAlign: "center" }}>
          *CO<sub>2</sub> captured by trees over 5 years.
        </p>
        <p className="font-13" style={{ textAlign: "center" }}>
          Donations are tax deductible.
        </p>
      </Container>
    </div>
  );
};

export default DonationOption;
