/* This component is used for FIGMA SCREEN 50. to show the take action first UI */

import React, { useEffect } from "react";
import { Button, Container } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import OurPartner from "../../Components/OurPartner";

const TakeAction = () => {
  const navigate = useNavigate();

  /* it will call only once while loading the component and set sctoll postition to the top */
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header title={"Take action"} hideBack={() => navigate("/")} />
      <div>
        <Container type="shadow" className={styles["top-card"]}>
          <div className="p-10">
            <p className={styles["subtitle"]}>Tree target</p>
            <div className={styles["heading"]}>100 Million</div>
          </div>
          <div className="p-10">
            <p className={styles["subtitle"]} style={{ width: 100 }}>
              <span
              className="font-18"
                style={{
                  fontFamily: "Inter-Bold",
                  
                  lineHeight: 1.5,
                }}
              >
                18
              </span>{" "}
              projects worldwide
            </p>
          </div>
        </Container>
        <br />
        <Container type="shadow" className="p-20">
          <p className="font-17"
            style={{
          
              lineHeight: 1.5,
              fontFamily: "Inter-Bold",
              color: "#282828",
            }}
          >
            We are partnering with the Priceless Planet Coalition to help us
            reach the goal of planting 100 million trees.
          </p>
          <p className="font-16" style={{ lineHeight: 1.6, color: "#282828" }}>
            Planting trees is a simple and powerful way to combat climate change
            by removing CO<sub>2</sub> from the air.
          </p>
          <div className="row-center mt-2">
            <Button
              onClick={() => navigate("/donation-option")}
              className={"solid-btn"}
            >
              Donate to plant trees
            </Button>
          </div>
          <br />
          <div>
            <div className="font-17"
              style={{
                
                fontFamily: "Inter-Bold",
                color: "#282828",
              }}
            >
              Who plants the trees?
            </div>
            <br />
            <div className="font-16" style={{ lineHeight: 1.6, color: "#282828" }}>
              Two global organizations, Conservation International and World
              Resources Institute, manage the tree planting efforts. They
              select, implement and monitor each restoration project as
              dedicated stewards of the planet.
            </div>

            <br />
            <p
            className="font-14"
              style={{
                textAlign: "center",
                color: "#282828",
                fontFamily: "Inter-Medium",
                
              }}
            >
              100% of your donation goes directly to Conservation International.
            </p>
            <br />
            <div
             className="font-14"
              style={{
                textAlign: "center",
                color: "#686868",
                fontFamily: "Inter-Light",
               
              }}
            >
              *CO<sub>2</sub> captured by trees over 5 years.Donations are tax
              deductible.
            </div>
          </div>
        </Container>
        <OurPartner />
        <br />
        <br />
      </div>
    </div>
  );
};

export default TakeAction;
