/* This component is used for FIGMA SCREEN 53. to show the Donation Payment method card UI */


import React, { useEffect } from "react";
import {
  Button,
  Container,
} from "@toqio/toqio-web-components";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import PoweredBy from "../../Components/PoweredBy";

const PaymentMethod = () => {
  const navigate = useNavigate();

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header title={"Take action"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
        <div className="font-22" style={{ fontFamily: "Inter-SemiBold" }}>
          Payment method
        </div>
        <br />
        <div
        className="font-14"
          style={{
            width: "100%",
             
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>We will charge $4 to </div>
          <img
            src={require("../../assets/mastercard.png")}
            style={{ height: 12, marginLeft: 8, marginRight: 8 }}
          />
          <div> .... 3456</div>
        </div>
        <br />

        <br />

        <div className="row-center mt-2 mb-2">
          <img
            src={require("../../assets/purple.png")}
            style={{ width: "50%", margin: "10px auto" }}
          />
        </div>

        <br />
        <br />

        <div className="row-center mt-2">
          <Button
            className={"solid-btn"}
            onClick={() => navigate("/lets-view")}
          >
            Continue
          </Button>
        </div>
      </Container>
      <PoweredBy style={{ bottom: 0 }} />
    </div>
  );
};

export default PaymentMethod;
