/* this component is used to create the common header after onboarding step */

import React from "react";
import { MdOutlineArrowBackIosNew, MdClose } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { TopBar } from "@toqio/toqio-web-components";
import { useNavigate } from "react-router-dom";

const Header = ({ title }) => {
  const navigate = useNavigate();

  return (
    <TopBar style={{ backgroundColor: 'transparent' }}>
      <MdOutlineArrowBackIosNew
        onClick={() => navigate(-1)}
        size={"24px"}
        color="#000"
        className="pointer"
        style={{marginLeft:-3}}
      />

      <div className="font-20" style={{ color: "#000",fontFamily:'Inter-Bold' }}>{title}</div>

      <IoIosMore className="pointer" onClick={()=>navigate('/settings')} size={"28px"} color="#000" />
    </TopBar>
  );
};

export default Header;
