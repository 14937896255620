/* This component is used for FIGMA SCREEN 33. to show the list of learn more item */


import React, { useEffect } from "react";
import {
  Container,
} from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import OurPartner from "../../Components/OurPartner";

const LearnMore = () => {
  const navigate = useNavigate();
  const topCard = {
    title: "What is CO<sub>2</sub>",
    img: require("../../assets/co2.png"),
  };
  const sliderData = [
    {
      title: "What is the carbon calculator?",
      img: require("../../assets/onboarding/carbon_calculator.png"),
    },
    {
      title: "Why should I care about CO<sub>2</sub>?",
      img: require("../../assets/care_co2.png"),
    },
  ];

  const sliderData2 = [
    {
      title: "How do i compare to others?",
      img: require("../../assets/compare.png"),
    },
    {
      title: "Why trees matter",
      img: require("../../assets/tree.png"),
    },
  ];

  

  const PricelessData = [
    {
      title: "About Priceless Planet Coalition",
      img: require("../../assets/onboarding/carbon_calculator.png"),
    },
    {
      title: "Where are projects located?",
      img: require("../../assets/earth2.png"),
    },
  ];

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);
  return (
    <div className="res-container">
      <Header title={"Learn More"} hideBack={() => navigate("/")} />
      <div style={{ width: "100%", maxWidth: 600, margin: "0px auto" }}>
        <Container
          type="shadow"
          className={styles["container"]}
          onClick={() => navigate("details/1")}
        >
          <img src={topCard.img} className={styles["crousel-image"]} />
          <p
            style={{ marginLeft: 10 }}
            className={styles["title"]}
            dangerouslySetInnerHTML={{ __html: topCard.title }}
          />
        </Container>
        <div className="row-between">
          {sliderData?.map((itm, index) => (
            <Container
              onClick={() => navigate("details/" + (index + 2))}
              key={index.toString()}
              type="shadow"
              className={styles["small-container"]}
            >
              <img src={itm.img} className={styles["crousel-image-small"]} />
              <p
                className={styles["title-small"]}
                dangerouslySetInnerHTML={{ __html: itm.title }}
              />
            </Container>
          ))}
        </div>
        <div className="row-between" style={{ width: "100%" }}>
          {sliderData2?.map((itm, index) => (
            <Container
              onClick={() => navigate("details/" + (index + 4))}
              key={index.toString()}
              type="shadow"
              className={styles["small-container"]}
            >
              <img src={itm.img} className={styles["crousel-image-small"]} />
              <p
                className={styles["title-small"]}
                dangerouslySetInnerHTML={{ __html: itm.title }}
              />
            </Container>
          ))}
        </div>
        <br />
        <br />
        <div style={{ width: "100%" }}>
          <div className={styles["title"]} style={{ height: 20 }}>
            Priceless Planet Coalition
          </div>
          <p className={styles["desc"]}>
            The coalition oversees all reforestation efforts
          </p>
        </div>
        <div className="row-between">
          {PricelessData?.map((itm, index) => (
            <Container
              onClick={() =>
                index === 0
                  ? navigate("pricessless")
                  : navigate("pricesless-project")
              }
              key={index.toString()}
              type="shadow"
              className={styles["small-container"]}
            >
              <img
                src={itm.img}
                className={
                  index === 0
                    ? styles["crousel-image-small"]
                    : styles["crousel-image-earth"]
                }
              />
              <p
                className={styles["title-small"]}
                dangerouslySetInnerHTML={{ __html: itm.title }}
              />
            </Container>
          ))}
        </div>
        <br />
        <OurPartner />
        <br />
        <br />
      </div>
    </div>
  );
};

export default LearnMore;
