/* This component is used to make the UI for content of privacy policy from the settings  */

import { Container } from "@toqio/toqio-web-components";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import { API_PRIVACY_POLICY } from "../../Services/ApiConstant";
import { getRequest } from "../../Services/RequestServices";
import styles from "./styles.module.css";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");

  useEffect(() => {
    getContent();
  }, []);

  /* this function will called to get the content of privacy policy */
  const getContent = () => {
    getRequest(API_PRIVACY_POLICY, true).then((res) => {
      console.log(res);
      setContent(res?.data?.pp);
    });
  };

  return [
    <div className="res-container">
      <Header title={"Privacy notice"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
        <div className={styles["desc"]}>{content}</div>
      </Container>
    </div>,
  ];
};

export default PrivacyPolicy;
