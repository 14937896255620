/* this component is used to create the footer of all detail page of learn more section */

import React from "react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { PRIMARY_COLOR } from "../Constants/color";

const CO2Footer = ({ onClick, style, className, title, hideTitle, desc }) => {
  return (
    <div
      onClick={onClick}
      style={{ marginTop: 40, ...style }}
      className={className}
    >
      {!hideTitle && (
        <div className="font-22" style={{ fontFamily: "Inter-Bold" }}>
          Next up
        </div>
      )}
      <div
        className="pointer"
        style={{ display: "flex", alignItems: "center", marginTop: 20 }}
      >
        {hideTitle && <SlArrowLeft size={"20px"} color={PRIMARY_COLOR} />}
        <div
          className="font-18"
          style={{
            color: PRIMARY_COLOR,
            lineHeight: 1.5,
            fontWeight: "Inter-Medium",
          }}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
        {!hideTitle && <SlArrowRight size={"20px"} color={PRIMARY_COLOR} />}
      </div>
    </div>
  );
};

export default CO2Footer;
