/* This component is used for FIGMA SCREEN 31. for getting the content on clicking of info icon on Category transation UI */


import React, { useEffect } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Container } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";

const CategoryInfo = () => {
  const navigate = useNavigate();

  /* it will call only once while loading the component and set sctoll postition to the top */
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header
        title={"A closer look at calculations"}
        hideBack={() => navigate("/")}
      />
      <Container type="shadow" className={styles["top-card"]}>
        <img
          src={require("../../assets/lifecycle.png")}
          style={{ width: "100%" }}
        />

        <div className="p-20">
          <div className={styles["title"]}>
            CO<sub>2</sub> life cycle
          </div>
          <br />
          <div className={styles["desc"]}>
            There is CO<sub>2</sub> generated throughout every product’s life
            cycle. For example, this includes the manufacturing of the product
            you buy as well as the CO<sub>2</sub> associated with all the stages
            of the product’s life, including disposal.
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CategoryInfo;
