/* This component is used for FIGMA SCREEN 01,02,03 and 04. to show the onboarding UI for very new user */

import React, { useState,useEffect } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styles from "./styles.module.css";
import { Link } from "@toqio/toqio-web-components";

import { BsArrowRight } from "react-icons/bs";
import OnboardHeader from "../../Components/OnboardHeader";
import { useNavigate } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import Button from "../../Components/Button";

const Home = () => {
  const navigate = useNavigate();

  const sliderData = [
    {
      title: "Understand your carbon footprint",
      desc: "The Carbon Calculator lets you see the environmental impact of your card spend and seamlessly tracks your estimated carbon footprint over time and against global benchmarks",
      img: require("../../assets/onboarding/newimg/device_one.png"),
    },
    {
      title: "Consider making changes",
      desc: "Learn more about carbon and how making small changes to your lifestyle and spending habits can help lower your environmental impact",
      img: require("../../assets/onboarding/newimg/device_two.png"),
    },
    {
      title: "How is my footprint calculated?",
      desc: "For your privacy, we cannot see what you buy. Instead, we estimate the CO<sub>2</sub> impact of your transactions based on the <strong>type of store</strong> where you shop, like a grocery store, bakery or restaurant, and how much you spend.",
      img: require("../../assets/onboarding/newimg/device_three.png"),
    },
    {
      title: "A step in the right direction",
      desc: "We are committed to helping the environment by partnering with worldwide reforestation projects. We welcome you to join us as we move towards a more sustainable world for us all",
      img: require("../../assets/onboarding/newimg/device_four.png"),
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  /* it will call to return each slider item of onboarding screen */
  const renderItem = ({ title, desc, img }) => (
    <div className={styles["crousel-item"]}>
      <img src={img} className={currentSlide === 3 ?styles["crousel-image"] :styles["crousel-image"]} />
      <div className={styles["text-container"]}>
        <p className={styles["title"]}>{title}</p>
        <p
          className={styles["desc"]}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </div>
    </div>
  );

/* it will call only once while loading the component and set sctoll postition to the top */  
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0, 0)
    },10)
  

},[])

/* it will call to get dynamic height of slider content for onboarding slider item */
  const getHeight = () => {
    let height = 0;
    if (window.screen.width > 450) {
      height = 80;
    } 
    else if (window.screen.width > 400) {
      height = 115;
    }
    else if (window.screen.width > 350) {
      height = 125;
    }
    else  {
      if (currentSlide === 3) {
        height = 150;
      } else {
        height = 150;
      }
    }

    return height;
  };
  return [
    <div
      style={{maxWidth:600,margin:"0px auto", backgroundColor: "#fff",paddingLeft:0,paddingRight:0, height: "100vh" }}
    >
      {currentSlide > 0 && (
        <OnboardHeader
          hideBack={() =>
            currentSlide > 0 ? setCurrentSlide(currentSlide - 1) : null
          }
          hideClose={() => console.log("hide")}
        />
      )}

      {currentSlide === 0 && <OnboardHeader />}

      <div className={`${styles["crousel-container"]}`}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={getHeight()}
          totalSlides={4}
          currentSlide={currentSlide}
        >
          <Slider>
            {sliderData.map((slideItem, index) => (
              <Slide key={index.toString()} index={index}>
                {renderItem(slideItem)}
              </Slide>
            ))}
          </Slider>

          <div className={styles["button-container"]}>
            {currentSlide === 3 && (
              <Link onClick={() => navigate("/what-is-carbon-calculator")}>
                <div className={styles['link-text']}>
                  Learn more about calculating your footprint <SlArrowRight />{" "}
                </div>
              </Link>
            )}
            {currentSlide < 3 ? (
              <Button
                className="solid-circle-btn mt-1"
                onClick={() =>
                  setCurrentSlide(currentSlide < 3 ? currentSlide + 1 : 3)
                }
              >
                <BsArrowRight color="#fff" size={"20px"} />
              </Button>
            ) : (
              <Button
                className={"solid-btn"}
                onClick={() => navigate("/privacy-notice")}
              >
                Yes, I'm interested
              </Button>
            )}
          </div>
          <div className={`${styles["button-container"]} mt-1`}>
            <DotGroup
              showAsSelectedForCurrentSlideOnly={true}
              renderDots={(props) => (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}
                >
                  <div
                    className={`crousel-dot ${
                      props.currentSlide === 0
                        ? "crousel-dot-active"
                        : "crousel-dot-inactive"
                    }`}
                  >
                    {" "}
                  </div>
                  <div
                    className={`crousel-dot ${
                      props.currentSlide === 1
                        ? "crousel-dot-active"
                        : "crousel-dot-inactive"
                    }`}
                  >
                    {" "}
                  </div>
                  <div
                    className={`crousel-dot ${
                      props.currentSlide === 2
                        ? "crousel-dot-active"
                        : "crousel-dot-inactive"
                    }`}
                  >
                    {" "}
                  </div>
                  <div
                    className={`${styles["crousel-dot"]} ${
                      props.currentSlide === 3
                        ? "crousel-dot-active"
                        : "crousel-dot-inactive"
                    }`}
                  >
                    {" "}
                  </div>
                </div>
              )}
            />
          </div>
        </CarouselProvider>
      </div>
    </div>,
  ];
};

export default Home;
