/* This component is used to show the comming soon UI */


import React from "react";
import { EmptyState, spacing } from "@toqio/toqio-web-components";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const ComingSoon = () => {
  return (
    <div className="app-container" style={spacing("m", 5, true)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          textAlign: "center",
        }}
      >
        <EmptyState
          description="Learn how your carbon footprint is calculated and get tips on how to reduce your footprint when you spend.  Designed in collaboration with industry leaders, these insights can help you make  practical and simple modifications leading to more mindful spending.  And you can easily compensate for your spending by planting trees or donating to climate justice-related causes."
          firstButton={{
            label: "Learn More about Priceless Planet Coalition!",
            onClick: () =>
              openInNewTab(
                "https://www.mastercard.co.uk/en-gb/vision/priceless-causes/nurture/priceless-planet.html"
              ),
            size: "m",
            variant: "primary",
            backgroundColor: "#8acce2",
            borderColor: "#8acce2",
            fontColor: "#ffffff",
          }}
          illustration="https://dktjvr8ouliwm.cloudfront.net/images/mcppc.jpg"
          title="Carbon Calculator from Mastercard & Priceless Planet (Coming Soon!)"
        />
      </div>
    </div>
  );
};

export default ComingSoon;
