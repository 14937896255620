/* This component is used for FIGMA SCREEN 55. to show the Thank you page after completing the donation from take action UI */

import React from "react";
import { Button, Container } from "@toqio/toqio-web-components";
import { useNavigate } from "react-router-dom";
import { PRIMARY_COLOR } from "../../Constants/color";
import PoweredBy from "../../Components/PoweredBy";
import { GiPalmTree } from "react-icons/gi";
import { BsCloudy } from "react-icons/bs";
import Header from "../../Components/Header";

const TakeActionThankYou = () => {
  const navigate = useNavigate();

  var twShareLink = "text=" + "descript";

  return [
    <div className="res-container">
      <Header title={"Take action"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
        <div className="font-22"
          style={{
            fontFamily: "Inter-SemiBold",
            textAlign: "center",
          
          }}
        >
          Thank you
        </div>
        <br />

        <div
          className="font-14"
          style={{
            lineHeight: 1.5,
            textAlign: "center",
            color: "#282828",
          }}
        >
          You helped rebuild our planet’s forests. Check your email for a
          confirmation and tax receipt. We'll let you know when your trees
          arrive at one of our projects. You are donating{" "}
        </div>

        <br />

        <div
          style={{
            width: "95%",
            margin: "20px auto",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontFamily: "Inter-Bold",
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: PRIMARY_COLOR,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  fontFamily: "Inter-Bold",
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  backgroundColor: "#F0F0F0",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                $
              </div>
            </div>
            <div
              className="mt-1 font-16"
              style={{ fontFamily: "Inter-Bold"}}
            >
              $4
            </div>
            <div className="font-10" style={{ marginTop: 2,  color: "#686868" }}>
              Donated
            </div>
          </div>
          <div
            style={{
              fontFamily: "Inter-Bold",
              width: 26,
              height: 26,
              borderRadius: 13,
              backgroundColor: "#F0F0F0",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            =
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: PRIMARY_COLOR,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <GiPalmTree size={25} />
            </div>
            <div
              className="mt-1 font-16"
              style={{ fontFamily: "Inter-Bold" }}
            >
              2
            </div>
            <div className="font-10" style={{ marginTop: 2,  color: "#686868" }}>
              Trees
            </div>
          </div>

          <div
            style={{
              fontFamily: "Inter-Bold",
              width: 26,
              height: 26,
              borderRadius: 13,
              backgroundColor: "#F0F0F0",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            =
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: PRIMARY_COLOR,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <BsCloudy style={{ marginBottom: 5 }} size={25} />
            </div>
            <div
              className="mt-1 font-16"
              style={{ fontFamily: "Inter-Bold" }}
            >
              -40
            </div>
            <div className="font-10" style={{ marginTop: 2,  color: "#686868" }}>
              kg CO<sub>2</sub>*
            </div>
          </div>
        </div>
        <br />
        <p className="font-18"
          style={{
            
            textAlign: "center",
            fontFamily: "Inter-Bold",
          }}
        >
          Tell your friends
        </p>
        <div
          className="row-between"
          style={{ width: "50%", margin: "25px auto" }}
        >
          <img
            onClick={() =>
              window.open(
                "http://twitter.com/intent/tweet?" + twShareLink,
                "pop",
                "width=600, height=400, scrollbars=no"
              )
            }
            src={require("../../assets/twitter.png")}
            style={{ width: 30, height: 30 }}
          />
          <img
            onClick={() =>
              window.open(
                "https://www.facebook.com/sharer/sharer.php?u=https://dev.toqio-integration-app.healrworld.co.uk&description=" +
                  "hjhfjhff",
                "pop",
                "width=600, height=400, scrollbars=no"
              )
            }
            src={require("../../assets/facebook.png")}
            style={{ width: 30, height: 30 }}
          />
          <img
            src={require("../../assets/instagram.png")}
            style={{ width: 30, height: 30 }}
          />
        </div>

        <br />

        <div className="divider"></div>
        <br />
        <p className="font-18"
          style={{
         
            textAlign: "center",
            fontFamily: "Inter-Bold",
          }}
        >
          {" "}
          Your donations to date:
        </p>

        <br />
        <div
          style={{
            width: "70%",
            margin: "20px auto",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: PRIMARY_COLOR,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <GiPalmTree size={25} />
            </div>
            <div
              className="mt-1 font-16"
              style={{ fontFamily: "Inter-Bold" }}
            >
              32
            </div>
            <div className="font-10" style={{ marginTop: 2,  color: "#686868" }}>
              Trees
            </div>
          </div>

          <div
            style={{
              fontFamily: "Inter-Bold",
              width: 26,
              height: 26,
              borderRadius: 13,
              backgroundColor: "#F0F0F0",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            =
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: PRIMARY_COLOR,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <BsCloudy style={{ marginBottom: 5 }} size={25} />
            </div>
            <div
              className="mt-1 font-16"
              style={{ fontFamily: "Inter-Bold" }}
            >
              -640
            </div>
            <div className="font-10" style={{ marginTop: 2,  color: "#686868" }}>
              kg CO<sub>2</sub>*
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="divider"></div>
        <br />

        <div className="row-center mt-2">
          <Button
            className={"solid-btn"}
            onClick={() => navigate("/dashboard")}
          >
            View my dashboard
          </Button>
        </div>
        <br />

        <p className="font-13" style={{ textAlign: "center" }}>
          *CO<sub>2</sub> captured by trees over 5 years.
        </p>
      </Container>
      <br />
      <PoweredBy />

      <br />
    </div>,
  ];
};

export default TakeActionThankYou;
