/* this is the root file of the component which is call first time while loading the appliation */

import "./App.css";
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Marketplace from "./pages/Marketplace";
import SPEC from "./pages/SPEC";
import CarbonCalculator from "./pages/CarbonCalculator";
import Music4ClimateImpactFund from "./pages/Music4ClimateImpactFund";
import Home from "./pages/Home";
import { ThemeProvider, Button, Spinner } from "@toqio/toqio-web-components";
import { theme } from "./utils/theme";
import PrivacyNotice from "./pages/PrivacyNotice";
import ThankYou from "./pages/ThankYou";
import Dashboard from "./pages/Dashboard";
import LearnMore from "./pages/LearnMore";
import Details from "./pages/Details";
import PriceLessDetails from "./pages/PriceLessDetails";
import PriceslessProject from "./pages/PriceslessProject";
import CategoryList from "./pages/CategoryList";
import CategoryDetails from "./pages/CategoryDetails";
import CategoryInfo from "./pages/CategoryInfo";
import AllTransaction from "./pages/AllTransaction";
import CategoryInfoDetail from "./pages/CategoryInfoDetail";
import Co2PM from "./pages/Co2PM";
import { TourProvider } from "@reactour/tour";
import { BsArrowRight } from "react-icons/bs";
import QuickTips from "./pages/QuickTips";
import QuickTipsDetails from "./pages/QuickTipsDetails";
import Settings from "./pages/Settings";
import SettingDetails from "./pages/SettingDetails";
import TakeAction from "./pages/TakeAction";
import DonationOption from "./pages/DonationOption";
import PaymentMethod from "./pages/PaymentMethod";
import LetsView from "./pages/LetsView";
import TakeActionThankYou from "./pages/TakeActionThankYou";
import { getRequest, postRequest } from "../src/Services/RequestServices";
import {
  API_CC_ENABLED,
  API_ONBOARDING_SCREEN_SHOWN,
  API_UPDATE_TUTORIAL,
} from "../src/Services/ApiConstant";
import ErrorMessage from "./Components/ErrorMessage";
import { getCookie, setCookie } from "./cookieHandler";
import ComingSoon from "../src/pages/ComingSoon";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Music4ClimateImpact from "./pages/Music4ClimateImpact";


/* this function is used to create the close button in tutorial UI */
function Close({ onClick }) {
  return (
    <button
      onClick={onClick}
      style={{ position: "absolute", zIndex: 1000, right: 10, top: 0 }}
    >
      x
    </button>
  );
}

function App() {
  const steps = [
    {
      selector: ".first-step",
      content: "See your CO<sub>2</sub> impact by spend category",
    },
    {
      selector: ".second-step",
      content: "Learn where your CO<sub>2</sub> impact is greatest",
    },
    {
      selector: ".third-step",
      content: "Understand the CO<sub>2</sub> impact of each transaction",
    },
  ];

  const [userInfo, setUserInfo] = useState();
  const [isValid, setIsValid] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const [ccenabled, setCCEnabled] = useState();
  const [isOnboarding, setIsOnboarding] = useState();

  /* this function will use to extract the user info from jwt token and set the user details in cookies */
  useEffect(() => {
    const user = getCookie("user");
    console.log(user);
    if (!user) {
      const jwt =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEyMzQiLCJuYW1lIjoiSm9obiBEb2UifQ.mMv_2Q7cp-Ype86mkDKFzT8WgzgGt7xv7Pw5OeAH_bo";
      window.addEventListener("message", function (event) {
        // If it is not a JWT event we do nothing
        console.log("event===", JSON.stringify(event));
        if (event?.data?.id !== "toqio-jwt") {
          // return;
        } else if (event?.data?.jwt) {

          // call validate jwt token api

          // setUserInfo(jwt_decode(event.data.jwt));
          console.log(jwt_decode(event.data.jwt));
        }
      });
      console.log(jwt_decode(jwt));
      setUserInfo({ ...jwt_decode(jwt) });
      setCookie("user", JSON.stringify({ ...jwt_decode(jwt) }));
    } else {
      console.log(JSON.parse(user));
      setUserInfo(JSON.parse(user));
    }
  }, []);


  /* this function will user check the ccEnabled and onboarding flag from cookies if this values are not availble in cokkies then it will call the api to check the current status of ccEnabled and onboarding and update the cookies */
  useEffect(() => {
    const status = getCookie("status");

    if (status) {
      console.log(JSON.parse(status));
      const { ccenabled, onboarding } = JSON.parse(status);

      setCCEnabled(ccenabled);
      setIsOnboarding(onboarding);
      setIsLoading(false);
    } else if (userInfo?.id) {
      getRequest(API_CC_ENABLED)
        .then((res) => {
          setCCEnabled(res?.data?.ccenabled);
          getRequest(API_ONBOARDING_SCREEN_SHOWN)
            .then((resp) => {
              setIsOnboarding(resp?.data?.onboarding);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              setIsValid(false);
            });
        })
        .catch(() => {
          setIsLoading(false);
          setIsValid(false);
        });
    }
  }, [userInfo]);

  /* this function will use to update the cookie for ccEnabled and onboarding flag */

  useEffect(() => {
    if (ccenabled && isOnboarding) {
      const status = { ccenabled, onboarding: isOnboarding };

      setCookie("status", JSON.stringify(status));
    }
  }, [ccenabled, isOnboarding]);

  /* this function is use to create the UI for tutorial screen */

  function ContentComponent(props) {
    const isLastStep = props.currentStep === props.steps.length - 1;
    const content = props.steps[props.currentStep].content;


/* this function is use to call the api to update status of tutorial so next time user will not see the tutorial UI */
    const onUpdateTutorial = () => {
      if (isLastStep) {
        postRequest(API_UPDATE_TUTORIAL, {
          tutorial: true,
        }).then((res) => {
          props.setIsOpen(false);
          localStorage.setItem("isTour", "1");
          window.location.href='/dashboard'
        });
      } else {
        props.setCurrentStep((s) => s + 1);
      }
    };
    return (
      <div
        style={{
          padding: 10,
          top: "unset",
          paddingTop: 50,
          bottom: 0,
          height: "100%",
          background: "transparent",
        }}
      >
        {typeof content === "function" ? (
          content({ ...props, someOtherStuff: "Custom Text" })
        ) : (
          <p
            style={{ color: "#fff", marginTop: 50 }}
            dangerouslySetInnerHTML={{ __html: content }}
          ></p>
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          {isLastStep ? (
            <Button className="solid-btn" onClick={() => onUpdateTutorial()}>
              Done
            </Button>
          ) : (
            <div style={{ width: 60, height: 60 }}>
              <Button
                className="solid-circle-btn"
                onClick={() => {
                  if (isLastStep) {
                    props.setIsOpen(false);
                  } else {
                    props.setCurrentStep((s) => s + 1);
                  }
                }}
                style={{ paddingTop: 15 }}
              >
                <div>
                  <BsArrowRight color="#fff" size={"20px"} />
                </div>
              </Button>
            </div>
          )}
        </div>

        <div className={`button-container mt-2`}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className={`crousel-dot ${
                props.currentStep === 0
                  ? "crousel-dot-active"
                  : "crousel-dot-inactive"
              }`}
            >
              {" "}
            </div>
            <div
              className={`crousel-dot ${
                props.currentStep === 1
                  ? "crousel-dot-active"
                  : "crousel-dot-inactive"
              }`}
            >
              {" "}
            </div>
            <div
              className={`crousel-dot ${
                props.currentStep === 2
                  ? "crousel-dot-active"
                  : "crousel-dot-inactive"
              }`}
            >
              {" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <TourProvider
        steps={steps}
        ContentComponent={ContentComponent}
        styles={{ popover: (base) => ({ ...base, padding: 0 }) }}
        showBadge={false}
        showDots={true}
        showCloseButton={false}
        nextButton={(props) => null}
        prevButton={() => null}
        components={{ Close, nextButton: (props) => null }}
      >
        {!isLoading && isValid ? (
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={isOnboarding && ccenabled ? <Dashboard /> : <Home />}
              />

              <Route path="/privacy-notice" element={<PrivacyNotice />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/dashboard/carbon-calculator"
                element={<CarbonCalculator />}
              />
              <Route path="/carbon-calculator" element={<ComingSoon />} />
              <Route
                path="/what-is-carbon-calculator"
                element={<CarbonCalculator />}
              />
              <Route
                path="/terms-condition"
                element={<TermsConditions />}
              />
               <Route
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route path="/learn-more" element={<LearnMore />} />
              <Route path="/learn-more/details/:id" element={<Details />} />
              <Route
                path="/learn-more/pricessless"
                element={<PriceLessDetails />}
              />
              <Route
                path="/learn-more/pricesless-project"
                element={<PriceslessProject />}
              />
              <Route path="/category-list" element={<CategoryList />} />
              <Route
                path="/category-details/:category"
                element={<CategoryDetails />}
              />
              <Route path="/category-info" element={<CategoryInfo />} />
              <Route path="/all-transactions" element={<AllTransaction />} />
              <Route
                path="/category-info-detail"
                element={<CategoryInfoDetail />}
              />
              <Route path="/co2pm" element={<Co2PM />} />
              <Route path="/quick-tips" element={<QuickTips />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route
                path="/quick-tips-details/:id"
                element={<QuickTipsDetails />}
              />
              <Route path="/spec" element={<SPEC />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/setting-details" element={<SettingDetails />} />
              <Route path="/take-action" element={<TakeAction />} />
              <Route path="/donation-option" element={<DonationOption />} />
              <Route path="/payment-method" element={<PaymentMethod />} />
              <Route path="/lets-view" element={<LetsView />} />
              <Route
                path="/take-action-thank"
                element={<TakeActionThankYou />}
              />
              <Route path="/error" element={<ErrorMessage />} />

              <Route
                path="/music4climate-impact-fund"
                element={<Music4ClimateImpactFund />}
              />
                <Route
                path="/music4climate-impact"
                element={<Music4ClimateImpact />}
              />
            </Routes>
          </Router>
        ) : !isLoading ? (
          <ErrorMessage />
        ) : (
          <div className="row-center w-100" style={{ height: "100vh" }}>
            {" "}
            <Spinner />
          </div>
        )}
      </TourProvider>
    </ThemeProvider>
  );
}

export default App;
