/* This component is used for FIGMA SCREEN 32. for getting all the Transaction Listing */


import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Button } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import moment from "moment";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { ImSpoonKnife } from "react-icons/im";
import { API_ALL_TRANSACTION } from "../../Services/ApiConstant";
import { getRequest } from "../../Services/RequestServices";

const AllTransaction = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());


  /* it will call to change the next date */
  const onNext = () => {
    setDate(moment(date).add(1, "month"));
  };

  
  /* it will call to change the previous date */
  const onPrev = () => {
    setDate(moment(date).add(-1, "month"));
  };

  const [txnInfo, setTxnInfo] = useState();

  const [data, setData] = useState([
    {
      txTitle: "Woolworths George St Sydney",
      value: "35.00",
      date: "16/04/22",
      carbonEmissionInGrams: "8.2",
      currencyCode: "$",
    },
    {
      txTitle: "Starbucks Milsons Point",
      value: "35.00",
      date: "16/04/22",
      carbonEmissionInGrams: "7.3",
      currencyCode: "$",
    },
    {
      txTitle: "Woolworths George St Sydney",
      value: "35.00",
      date: "16/04/22",
      carbonEmissionInGrams: "3.6",
      currencyCode: "$",
    },
    {
      txTitle: "Woolworths George St Sydney",
      value: "25.00",
      date: "16/04/22",
      carbonEmissionInGrams: "2.4",
      currencyCode: "$",
    },
    {
      txTitle: "McDonald's Rouse Hill",
      value: "35.00",
      date: "16/04/22",
      carbonEmissionInGrams: "4.2",
      currencyCode: "$",
    },
  ]);

  /* it will call to fetch all transactions based on selected date */

  useEffect(() => {
    const month = moment(date).format("MMMM");
    const yr = moment(date).format("YYYY");
    getRequest(`${API_ALL_TRANSACTION}/${month}/${yr}`).then((res) => {
      console.log(res);
      setTxnInfo(res.data);
    });
  }, [date]);


  /* it will fetch the date from localstorage which is set from dashboard */
  useEffect(() => {
    let dt = localStorage.getItem("date");
    if (dt) {
      dt = JSON.parse(dt);
      setDate(dt);
    }
  }, []);

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0, 0)
    },10)
  

},[])

  return (
    <div className="res-container">
      <Header title={"My transactions"} hideBack={() => navigate("/")} />
      <div style={{ background: "#fff" }}>
        <div
          
          style={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            fontFamily:'Inter-Bold',
            paddingTop:20,
            paddingBottom:20
          }}
        >
          <div
            className="row-end"
          >
             <div style={{ width: 40 }} className="row-center">
            {moment(date).isAfter(moment("2018-05-01", "YYYY-MM-DD")) && (
              <SlArrowLeft
                onClick={() => onPrev()}
                className="font-14 pointer"
              />
            )}
            </div>
            <div 
              className="font-16"
              style={{
                width: 130,
                display:'flex',
                justifyContent:'center'
               
              }}
            >{`${moment(date).format("MMMM YYYY")}`}</div>
             <div style={{ width: 40 }} className="row-center">
            {moment(date).isBefore(
              moment(new Date()).subtract("1", "months")
            ) && (
              <SlArrowRight
                onClick={() => onNext()}
                className="font-14"
                style={{ cursor: "pointer" }}
              />
            )}
            </div>
          </div>
        </div>
        <div className={styles["top-card"]}>
          <div>
            <p className="font-16" style={{ fontFamily: "Inter-Bold" }}>
              Carbon Calculator
            </p>
            <div className="font-12" style={{ color: "#282828" }}>
              Estimated footprint for April
            </div>
            <div className="font-24"
              style={{
                
                fontFamily: "Inter-Medium",
                color: "#282828",
              }}
            >
              {(txnInfo?.estimatedfootprint / 1000)?.toFixed(2)} kg CO2
            </div>
            <Button
              onClick={() => navigate("/dashboard")}
              style={{ lineHeight: 1 }}
              className="solid-btn mt-2"
            >
              View my dashboard
            </Button>
          </div>
          <div>
            <img
              src={require("../../assets/transaction.png")}
              style={{ width: 140 }}
            />
          </div>
        </div>

        <br />
        {data.map((itm) => (
          <div className={styles["transaction-type"]}>
            <div className="row-start">
              <div className="mr-1">
                <ImSpoonKnife
                  size={"35px"}
                  color="#32C7D9"
                  style={{ marginTop: 10 }}
                />
              </div>
              <div style={{ width: "90%" }} className="ml-2">
                <div className="row-between">
                  <div className="font-20"
                    style={{
                      fontFamily: "Inter-SemiBold",
                      width: "45%",
                    }}
                  >
                    {itm?.txTitle}
                  </div>
                  <div className="font-20"
                    style={{
                     
                      fontFamily: "Inter-SemiBold",
                      width: "40%",
                      textAlign: "right",
                    }}
                  >
                    {itm.currencyCode}
                    {itm.value}
                  </div>
                </div>
                <div className="row-between mt-2">
                  <div className="font-16" style={{color: "#686868", width: "40%" }}>
                    {itm.date}
                  </div>
                  <div
                  className="font-16"
                    style={{
                      color: "#686868",
                      width: "40%",
                      textAlign: "right",
                    }}
                  >
                    {itm.carbonEmissionInGrams} kg CO<sub>2</sub>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllTransaction;
