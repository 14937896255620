/* This component is used for FIGMA SCREEN 19. for showing the category list*/


import React, { useState } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Spinner,
} from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import { SlArrowRight } from "react-icons/sl";
import moment from "moment";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect } from "react";
import { getRequest } from "../../Services/RequestServices";
import { API_TOP_CATEGORIES } from "../../Services/ApiConstant";
import { getIcon } from "../../Constants/icons";
import { PRIMARY_COLOR } from "../../Constants/color";

const CategoryList = ({data}) => {
  const navigate = useNavigate();
  const [date, setDate] = useState();

  const [categoreis, setCategories] = useState(data);
  const [isLoading, setIsLoading] = useState(data?false:true);

  /* it will call to fetch only category list based on selected date from dashboard */
  useEffect(() => {
    let dt = localStorage.getItem("date");
    if(!data && dt){
        dt = JSON.parse(dt);
        setDate(dt);
        const month = moment(dt).format("MMMM");
        const yr = moment(dt).format("YYYY");
        getRequest(`${API_TOP_CATEGORIES}/${month?.toUpperCase()}/${yr}`)
          .then((res) => {
            console.log(res);
            if (res.data?.topcategory?.length > 0) {
              setCategories(res.data?.topcategory);
            }
            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
          });
    }
    else{
      setCategories(data);
      setIsLoading(false);
    }
  
  }, []);

  /* it will call only once while loading the component and set sctoll postition to the top */

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header
        title={"Carbon emissions by category"}
        hideBack={() => navigate("/")}
      />
      <div style={{ background: "#fff" }}>
        <div
          className="row-between p-20 white font-16"
          style={{
            fontFamily: "Inter-SemiBold",
            color: "#686868",
          }}
        >
          <div></div>
          {moment(date).format("MMMM YYYY")}
          <AiOutlineInfoCircle
            className="pointer"
            onClick={() => navigate("/category-info-detail")}
            size={"25"}
            color={"#89cce2"}
          />
        </div>
        {!isLoading ? (
          <div
            className="p-20"
            style={{ width: "90%", paddingTop: 0, margin: "0px auto" }}
          >
            {categoreis.map((itm) => (
              <div
                key={itm.mainCategoryTitle}
                onClick={() =>
                  itm?.percentage > 0
                    ? navigate("/category-details/" + itm.mainCategoryRef)
                    : null
                }
                type="shadow"
                className={"second-step " + styles["container"]}
              >
                {getIcon(itm.mainCategoryIcon, itm.maincategoryColor, "35px")}
                <div className="w-90 ml-2">
                  <div className="row-between">
                    <div className={styles["title"]}>
                      {itm.mainCategoryTitle}
                    </div>
                    {itm?.percentage > 0 && (
                      <SlArrowRight
                        color="#686868"
                        className="font-14 pointer"
                      />
                    )}
                  </div>
                  <div className="row-between" style={{ alignItems: "center" }}>
                    <div
                      className="w-80 mt-1"
                      style={{
                        height: 4,
                        backgroundColor: "#F0F0F0",
                        overflow: "hidden",
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          width: itm.percentage + "%",
                          backgroundColor: itm.maincategoryColor
                            ? itm.maincategoryColor
                            : PRIMARY_COLOR,
                          height: 4,
                        }}
                      ></div>
                      {/* <ProgressBar color='red' style={{backgroundColor:'red'}} className='w-80' progress={itm.value} /> */}
                    </div>
                    <div
                      className="mt-1 font-14"
                      style={{ color: "#686868" }}
                    >
                      {itm.percentage}%
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="row-center mt-2">
              <Button
                className="solid-btn"
                onClick={() => navigate("/all-transactions")}
              >
                View all transactions
              </Button>
            </div>
          </div>
        ) : (
          <div className="row-center w-100" style={{ height: "40vh" }}>
            {" "}
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
