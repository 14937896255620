/* This component is used for FIGMA SCREEN 54. to show the Donation Lets View UI */


import React, { useEffect } from "react";
import {
  Button,
  Container,
} from "@toqio/toqio-web-components";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { PRIMARY_COLOR } from "../../Constants/color";
import PoweredBy from "../../Components/PoweredBy";

const LetsView = () => {
  const navigate = useNavigate();

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header title={"Take action"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
        <div className="font-22" style={{ fontFamily: "Inter-SemiBold" }}>
          Let's review
        </div>
        <br />
        <div
        className="font-14"
          style={{
            width: "100%",
             
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            You are donating{" "}
            <span style={{ fontFamily: "Inter-SemiBold" }}>$4</span> using{" "}
          </div>
          <img
            src={require("../../assets/mastercard.png")}
            style={{ width: 20, height: 10, marginLeft: 8, marginRight: 8 }}
          />
          <div> .... 3456</div>
        </div>
        <br />

        <p className="font-14" style={{ lineHeight: 1.5 }}>
          I agree to the{" "}
          <span style={{ color: PRIMARY_COLOR }}>Terms of Use</span> and{" "}
          <span style={{ color: PRIMARY_COLOR }}>Privacy Notice</span> for this
          Partnerbank service.
        </p>

        <br />

        <br />
        <br />
        <br />
        <br />

        <div className="row-center mt-2">
          <Button
            className={"solid-btn"}
            onClick={() => navigate("/take-action-thank")}
          >
            Donate now
          </Button>
        </div>
      </Container>
      <PoweredBy style={{ bottom: 0 }} />
    </div>
  );
};

export default LetsView;
