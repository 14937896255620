import { Container, EmptyState } from "@toqio/toqio-web-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";


const Music4ClimateImpact = () => {
  const navigate = useNavigate();


  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return [
    <div className="res-container">
      <Header title={" The Music4Climate® Impact Fund (501c3)"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
      <EmptyState
			description="Climate Change affects the most vulnerable in our society the most, including black, brown, and Indigenous communities as well as children. M4CJ will partner with Climate Justice beneficiaries to invest in climate projects and initiatives that will directly contribute to the goal of 1.5° C. by 2030 and support climate adaptation and resiliency for the most marginalized."
			firstButton={{
				label: 'Donate Now to Support Climate Justice!',
				onClick: () => openInNewTab('https://givebox.com/music4climatejustice'),
				size: 'm',
				variant: 'primary',
				backgroundColor: "#8acce2",
  				borderColor:"#8acce2",
  				fontColor:"#ffffff"
			}}
			illustration="https://dktjvr8ouliwm.cloudfront.net/images/music4climate-impact-fund.jpg"
			title="The Music4Climate® Impact Fund (501c3)"
			/>
      <br />
      </Container>
    </div>,
  ];
};

export default Music4ClimateImpact;
