/* this component is used to create the custom donut chart for dashboard using d3 library */

import * as d3 from "d3";
import { getImage } from "../Constants/image";

const drawChart = (element, data,totalMonthlyFootprint,navigate) => {


  d3.select(element).select("svg").remove(); // Remove the old svg



var width = window.screen.width *0.35,
    height = window.screen.width > 450 ?350:320,
    radius = 250,
    colors = d3.scale.ordinal()
        .range([ "#FFA833",
        "#FB853C",
        "#79B252",
        "#9455BE",
        "#BCBCBC",
        "#C74765",
        "#2BAAB9",
        "#32C7D9",]);

var image_width=40,
    image_height=40;

    



var pie = d3.layout.pie()
     .value(function(d) {
     return d.totalCarbonEmissionInGrams;
  })

var arc = d3.svg.arc()
   .outerRadius(window.screen.width > 450 ? 160:window.screen.width>400?150:140)
   .innerRadius(window.screen.width > 450 ? 100:window.screen.width>400?90:85)

var svg = ''




    if(window.screen.width >= 400 && window.screen.width < 600){
      width = window.screen.width *0.85
      svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate('+width/1.8+','+'165)');
    }
    else if(window.screen.width > 600 && window.screen.width < 900){
      width = window.screen.width *0.70
      svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate('+width/1.8+','+'165)');
    }
    else if(window.screen.width > 900 && window.screen.width <= 1024){
      width = window.screen.width *0.52
      svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate('+width/1.8+','+'165)');
    }
    else if(window.screen.width > 1024){
      width = window.screen.width *0.35
      svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate('+width/1.8+','+'165)');
    }
    else if(window.screen.width < 400){
      width = window.screen.width *0.91
      svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate('+width/1.95+','+width/2+')');
    }
    
    else{
      svg =  d3.select(element).append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate('+width/2+','+width/3+')');
    }

    svg.selectAll('path')
   .on('click', (d, i, n) => {
      console.log(d, i, n)
   })


   svg.append("text")
   
   .style("text-anchor", "middle")
   .style("font-size", "28px")
   .style("font-family", "Inter-SemiBold")
   .text((totalMonthlyFootprint/1000).toFixed(0))
   .attr('transform', 'translate(0,-10)');

   svg.append("text")
   
   .style("text-anchor", "middle")
   .style("font-size", "14px")
   .style("font-family", "Inter-SemiBold")
   .text('Kg CO2')
   .attr('transform', 'translate(0,10)');

   svg.append("text")
   
   .style("text-anchor", "middle")
   .style("font-size", "10px")
   .style("font-family", "Inter-Regular")
   .style("color", "grey")
   .text('Estimated')
   .attr('transform', 'translate(0,30)');
 
var g = svg.selectAll(".arc")
      .data(pie(data))
    .enter().append("g")
      .attr("class", "arc");

  g.append("path")
      .attr("d", arc)
      .style("fill", function(d,i) { return colors(i); });

  g.append("g")
      .attr("transform", function(d) { return "translate(" + arc.centroid(d) + ")"; })
    //   .html(() => d3.select().html())
   .append("svg:image")
  
      .attr("xlink:href",function(d) {return getImage(d.data.categoryIcon);})
      .attr("width",window.screen.width > 600 ?30:25)
      .attr("height",window.screen.width > 600 ?30:25)
      .attr("x",-1*image_width/2.5)
      .attr("y",-1*image_height/2.3);

};
    
export default drawChart;