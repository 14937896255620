/* This component is used for FIGMA SCREEN 30. for getting all the Transaction based on category */


import React, { useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, ProgressBar } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import moment from "moment";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { PRIMARY_COLOR } from "../../Constants/color";
import { useEffect } from "react";
import { getRequest } from "../../Services/RequestServices";
import { API_ALL_TRANSACTION } from "../../Services/ApiConstant";
import { getIcon } from "../../Constants/icons";

const CategoryDetails = () => {
  const navigate = useNavigate();

  const [categoryInfo, setCategoryInfo] = useState({
    mainCategoryTitle: "Food & Beverage",
    estimatedMonthlyFootprint: 28,
    mainCategoryColor: PRIMARY_COLOR,
    mainCategoryIcon: "",
    month: "February",
    year: "2023",
  });

  const data = [
    {
      txTitle: "Woolworths George St Sydney",
      value: "35.00",
      date: "16/04/22",
      currencyCode: "$",
      carbonEmissionInGrams: "8.2",
    },
    {
      txTitle: "Starbucks Milsons Point",
      value: "35.00",
      date: "16/04/22",
      currencyCode: "$",
      carbonEmissionInGrams: "7.3",
    },
    {
      txTitle: "Woolworths George St Sydney",
      value: "35.00",
      date: "16/04/22",
      currencyCode: "$",
      carbonEmissionInGrams: "3.6",
    },
    {
      txTitle: "Woolworths George St Sydney",
      value: "25.00",
      date: "16/04/22",
      currencyCode: "$",
      carbonEmissionInGrams: "2.4",
    },
    {
      txTitle: "McDonald's Rouse Hill",
      currencyCode: "$",
      value: "25.00",
      date: "16/04/22",
      carbonEmissionInGrams: "4.2",
    },
  ];

  let { category } = useParams();


  /* it will call to fetch the all transaction based on selected category and selected date */
  useEffect(() => {
    let dt = localStorage.getItem("date");
    if (dt) {
      dt = JSON.parse(dt);
      const month = moment(dt).format("MMMM");
      const yr = moment(dt).format("YYYY");
      getRequest(`${API_ALL_TRANSACTION}/${month}/${yr}/${category}`).then(
        (res) => {
          console.log(res);
          if (res?.data) {
            setCategoryInfo(res.data);
          }
        }
      );
    }
  }, [category]);

  /* it will call only once while loading the component and set sctoll postition to the top */
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header title={"Transaction category"} hideBack={() => navigate("/")} />
      <Container type="shadow" className={styles["top-card"]}>
        {/* <ImSpoonKnife size={'50px'} color="#32C7D9" style={{marginTop:10}}/> */}
        {getIcon(
          categoryInfo?.mainCategoryIcon,
          categoryInfo?.mainCategoryColor,
          "45px"
        )}
        <div className={styles["heading"]}>
          {categoryInfo.mainCategoryTitle}
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <AiOutlineInfoCircle
            className="pointer"
            onClick={() => navigate("/category-info")}
            size={"28"}
            color={PRIMARY_COLOR}
          />
        </div>
        <div className={styles["sub-title"]}>
          {categoryInfo.month} {categoryInfo.year}
        </div>
      </Container>
      <br />
      <div style={{ background: "#fff" }}>
        <div className={styles["grey-box"]}>
          <div
            style={{
              borderRight: "1px solid #686868",
              width: "55%",
              paddingLeft: 20,
            }}
          >
            <p className="font-14" style={{fontFamily: "Inter-Medium" }}>
              Estimated footprint for April
            </p>
            <div className="font-30" style={{  fontFamily: "Inter-Medium" }}>
              {categoryInfo?.estimatedMonthlyFootprint}
            </div>
          </div>

          <div style={{ paddingRight: 20 }}>
            <p className="font-14" style={{ fontFamily: "Inter-Medium" }}>
              Share of footprint
            </p>
            <div className="font-30"
              style={{
                
                fontFamily: "Inter-Medium",
                color: "#32C7D9",
              }}
            >
              22%
            </div>
          </div>
        </div>
        <br />
        {data.map((itm) => (
          <div className={"third-step " + styles["transaction-type"]}>
            <div className="row-between">
              <div className="font-20"
                style={{
                 
                  fontFamily: "Inter-SemiBold",
                  width: "45%",
                }}
              >
                {itm.txTitle}
              </div>
              <div className="font-20"
                style={{
                  
                  fontFamily: "Inter-SemiBold",
                  width: "40%",
                  textAlign: "right",
                }}
              >
                {itm.currencyCode}
                {itm.value}
              </div>
            </div>
            <div className="row-between mt-2">
              <div className="font-16" style={{  color: "#686868", width: "40%" }}>
                {itm.date}
              </div>
              <div className="font-16"
                style={{
                  
                  color: "#686868",
                  width: "40%",
                  textAlign: "right",
                }}
              >
                {itm.carbonEmissionInGrams} kg CO2
              </div>
            </div>
          </div>
        ))}
        <div className="row-center mt-2 p-20">
          <Button
            className="solid-btn"
            onClick={() => navigate("/all-transactions")}
          >
            View all transactions
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CategoryDetails;
