/* This component is used for FIGMA SCREEN 07. to show the content of privacy notice from onboarding step  */


import { Spinner } from "@toqio/toqio-web-components";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import OnboardHeader from "../../Components/OnboardHeader";
import { getCookie, setCookie } from "../../cookieHandler";
import { API_PRIVACY_POLICY, API_UPDATE_CC, API_UPDATE_ONBOARDING } from "../../Services/ApiConstant";
import { getRequest, postRequest } from "../../Services/RequestServices";
import styles from "./styles.module.css";

const PrivacyNotice = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [content,setContent] = useState('');


  useEffect(()=>{
    getContent();
  },[])

  

  /* this function will call to get the content of privacy notice */
  const getContent = () => {
    setLoading(true)
    getRequest(API_PRIVACY_POLICY,true).then((res)=>{
      setLoading(false)
      setContent(res?.data?.pp)
    })
  }

  /* this function will call to update the ccEnabled flag and based on that cookie value get updated with that user will get enable to use the application */

  const onEnable = () => {
    const user =  JSON.parse(getCookie('user'));
    postRequest(API_UPDATE_ONBOARDING,{
      onboarding: true
    }).then((res)=>{
      postRequest(API_UPDATE_CC,{
        "ccEnabled": true
      }).then((res)=>{
        const status = {ccenabled:true,onboarding:true};
        setCookie('status',JSON.stringify(status))
        navigate("/thank-you")
      })
    }).catch(e =>   console.log(e))
  }
  return [
    <div className="res-container">
      <OnboardHeader
        hideClose={() => navigate("/")}
        style={{ flexDirection: "row-reverse" }}
      />
      <div className={styles["container"]}>
        <p className={styles["header-title"]}>{"PRIVACY NOTICE"}</p>
        <p className={styles["title"]}>
          {
            "By clicking “Enable” I understand that Partnerbank will process my personal data as described in the Privacy Notice below"
          }
        </p>
        <div className={styles["grey-box"]}>
          <div className={styles["grey-box-title"]}>
            {"Use And Participation"}
          </div>
          <div className={styles["desc"]}>
            {!loading ? (
              <>
                {content}
              </>
            ):(
               <div className="row-center w-100" >
               {" "}
               <Spinner />
             </div>
            )}
          
          </div>
        </div>
        <Link to='/terms-condition' >
          <div className="font-16" style={{  marginBottom: 20, marginTop: 20 }}>
            Terms & Conditions
          </div>
        </Link>
        <div className={styles["terms-condition"]}>
          <input
            type="checkbox"
            style={{ marginTop: 6 }}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <div className={styles["terms-condition-text"]}>
            {
              "I agree that Partnerbank may process my personal data to calculate my carbon footprint via the Carbon Calculator."
            }
          </div>
        </div>

        <div className={styles["button-container"]}>
          <Button className={"outline-btn"} onClick={() => navigate("/")}>
            <div style={{ width: "50%" }}></div>Cancel
          </Button>
          <Button
            className={!isChecked ? "solid-btn disabled-btn" : "solid-btn"}
            onClick={() => onEnable()}
            disabled={!isChecked}
          >
            Enable
          </Button>
        </div>
      </div>
    </div>,
  ];
};

export default PrivacyNotice;
