/* This component is used for FIGMA SCREEN 20. for showing the transaction category explained */


import React, { useState } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Container, Link, Modal } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import { BsChevronDown } from "react-icons/bs";
import { SlArrowRight } from "react-icons/sl";
import { useEffect } from "react";
import { getRequest } from "../../Services/RequestServices";
import { API_CATEGORIES_EXPLAINED } from "../../Services/ApiConstant";
import { getIcon } from "../../Constants/icons";

const CategoryInfoDetail = () => {
  const navigate = useNavigate();

  const [category, setcategory] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [categoreis, setCategories] = useState([]);


/* it will call to fetch all categories list with subcategory details */
  useEffect(() => {
    getRequest("customer/" + API_CATEGORIES_EXPLAINED, true).then((res) => {
      console.log(res);
      setCategories(res.data.subcategory);
    });
  }, []);

  /* it will call only once while loading the component and set sctoll postition to the top */

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header
        title={"Carbon emissions by category"}
        hideBack={() => navigate("/")}
      />

      <Container
        type="shadow"
        style={{
          width: "100%",
          maxWidth: 600,
          overflow: "hidden",
          paddingTop: 0,
          margin: "20px auto",
        }}
      >
        <img
          style={{ width: "100%" }}
          src={require("../../assets/transaction_info.png")}
        />
        <div className="p-20">
          <div className={styles["desc"]}>
            We calculate your carbon footprint based on type of store, but for
            simplicity we display each store under a list of common transaction
            categories.
            <br />
            <br />
            Below are some ‘type of store’ examples for each transaction
            category.
          </div>

          <Link onClick={() => setIsOpen(true)}>
            <div 
            className="font-16"
              style={{
                fontFamily: "Inter-Light",
                margin: "20px auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              Tell me more <SlArrowRight />{" "}
            </div>
          </Link>
        </div>

        {categoreis.map((itm) => (
          <div>
            <div
              onClick={() =>
                itm.mcmainreftitle === category?.mcmainreftitle
                  ? setcategory()
                  : setcategory(itm)
              }
              type="shadow"
              className={styles["container"]}
            >
              {getIcon(itm.mainCategoryIcon, itm?.maincategorycolor, "28px")}
              <div className="w-90 ml-1">
                <div className="row-between">
                  <div className={styles["title"]}>{itm.maincategorytitle}</div>
                  <BsChevronDown
                    color="#686868"
                    className="font-14 pointer"
                  />
                </div>
              </div>
            </div>
            {itm.mcmainreftitle === category?.mcmainreftitle && (
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                {itm?.mcsub?.map((item) => (
                  <p className="font-14" style={{ color: "#282828" }}>{item}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </Container>

      <div>
        <Modal
          close={() => setIsOpen(false)}
          size="s"
          // title="Lorem ipsum."
          visible={isOpen}
          contentClassName="modal"
        >
          <div
            style={{
              width: "90%",
              margin: "0px auto",
              backgroundColor: "#fff",
            }}
          >
            <div className={styles["title-head"]}>
              What's the difference between transaction category and type of
              store?
            </div>
            <br />
            <div className={styles["desc-head"]}>
              We use the 'type of store' where you shop to calculate your carbon
              footprint and each type of store falls under a transaction
              category. For example, if you purchase a cup of coffee, your 'type
              of store' is a coffee shop which falls under the Food & Beverage
              category. In the Carbon Calculator, the types of stores where you
              shop all fall under one of several different transaction
              categories.
            </div>
            <br />
            <div className={styles["title-head"]}>
              How does all of this relate to my carbon footprint?
            </div>
            <br />
            <div className={styles["desc-head"]}>
              We calculate your personal carbon footprint based on your
              Partnerbank-linked card transactions and pay attention to the
              'type of store' where you shop.{" "}
            </div>
            <br />
            <div className={styles["title-head"]}>
              What role does the Åland Index play?
            </div>
            <br />
            <div className={styles["desc-head"]}>
              Each ''type of store' is assigned its own unique CO<sub>2</sub>{" "}
              emissions factor by the Åland Index and this factor, added to the
              transaction amount (how much you spend), is how we calculate your
              footprint. For your privacy, we don't see the items you buy.
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CategoryInfoDetail;
