/* This component is used to show the content of terms & conditions   */

import { Container } from "@toqio/toqio-web-components";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import {
  API_PRIVACY_POLICY,
} from "../../Services/ApiConstant";
import { getRequest } from "../../Services/RequestServices";
import styles from "./styles.module.css";

const TermsConditions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getContent();
  }, []);

  /* this function will used to get the content for terms & conditions */
  const getContent = () => {
    getRequest(API_PRIVACY_POLICY, true).then((res) => {
      console.log(res);
    });
  };



  return [
    <div className="res-container">
      <Header title={"Terms & Conditions"} hideBack={() => navigate("/")} />
      <Container type="shadow" className="p-20">
        <div className={styles["desc"]}>
          A terms and conditions agreement outlines the rules that your website
          or mobile app users must follow. They usually cover topics such
          restricted behavior, payment terms, acceptable use, and more that we
          cover below. Read on to learn more about terms and conditions
          agreements, why you should have one, and how to implement one using
          our sample terms and conditions template.
        </div>
      </Container>
    </div>,
  ];
};

export default TermsConditions;
