/* this is the common component which is used to show the our partner  */

import React  from "react";
import styles from "./styles.module.css";

const OurPartner = ({isTitle,className,isMasterCard,imageClass,width}) => {
  return (
      <div style={{ width: "100%", margin: "0px auto" }}>
       {!isTitle && ( <p className={styles["title"]} style={{ height: 0 }}>
          Our planting partners
        </p>
        )}
      <div
        className={className}
        style={{
          display:'flex',
          alignItems:'center',
          paddingTop:20
        }}
      >
        {isMasterCard && (
             <img
             src={require("../assets/mastercard.png")}
             style={{ width:"10%" }}
             className={imageClass}
           />
        )}
        <img
          src={require("../assets/ci.png")}
          style={{ width:  width ? width :"40%"}}
          className={imageClass}
        />
        <img
          src={require("../assets/wri.png")}
          style={{ width:  width ? width :"35%",marginLeft:isMasterCard?0:16 }}
          className={imageClass}
        />
      </div>
    </div>
  );
};

export default OurPartner;
