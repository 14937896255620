/* this component is used for  Music4Climate® Impact Fund */

import React from 'react';
import {
	EmptyState,
	spacing
} from '@toqio/toqio-web-components';

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const Music4ClimateImpactFund = () => {
	return (
		<div className="app-container" style={ spacing('m', 5, true) }>
			<div style={{
				display: 'flex ',
				justifyContent: 'space-around',
				textAlign: 'center'
			}}>
		  <EmptyState
			description="Climate Change affects the most vulnerable in our society the most, including black, brown, and Indigenous communities as well as children. M4CJ will partner with Climate Justice beneficiaries to invest in climate projects and initiatives that will directly contribute to the goal of 1.5° C. by 2030 and support climate adaptation and resiliency for the most marginalized."
			firstButton={{
				label: 'Donate Now to Support Climate Justice!',
				onClick: () => openInNewTab('https://www.music4climatejustice.org/beneficiaries#comp-kfcfy0yq'),
				size: 'm',
				variant: 'primary',
				backgroundColor: "#8acce2",
  				borderColor:"#8acce2",
  				fontColor:"#ffffff"
			}}
			illustration="https://dktjvr8ouliwm.cloudfront.net/images/music4climate-impact-fund.jpg"
			title="The Music4Climate® Impact Fund (501c3)"
			/></div>
		</div>
	  );
};

export default Music4ClimateImpactFund;
