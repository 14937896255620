import React from 'react';
import {
	EmptyState,
	spacing
} from '@toqio/toqio-web-components';

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const Marketplace = () => {
	return (
		<div className="app-container" style={ spacing('m', 5, true) }>
			<div style={{
        display: 'flex ',
        justifyContent: 'space-around',
		textAlign: 'center'
      }}>
		  <EmptyState
			description="Join millions of consumers and businesses who care about your commitment to the planet and people. Market your products and services to new prospects globally who are also committed to your values.  Track sales, leads and feedback from prospects and gain new customers."
			firstButton={{
				label: 'Join HealRWorld Community & Marketplace Now!',
				onClick: () => openInNewTab('https://edir.healrworld.com/advertise'),
				size: 'm',
				variant: 'primary',
				backgroundColor: "#8acce2",
  				borderColor:"#8acce2",
  				fontColor:"#ffffff"
			}}
			illustration="https://dktjvr8ouliwm.cloudfront.net/images/hrw-icon.jpg"
			title="HealRWorld Community & Marketplace"
			/></div>
		</div>
	  );
};

export default Marketplace;
