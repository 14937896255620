import React from 'react';
import {
	EmptyState,
	spacing
} from '@toqio/toqio-web-components';

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const Marketplace1 = () => {
	return (
		<div className="app-container" style={ spacing('m', 5, true) }>
			<div style={{
        display: 'flex ',
        justifyContent: 'space-around',
		textAlign: 'center'
      }}>
		  <EmptyState
			description="HealRWorld's proprietary Sustain Planet Earth Committed® tool allows small businesses to learn more about the UN SDGs and commit to one or more of the 17 Goals. Corporations, Governments and Consumers all support the SDGs and your commitment will help you stand out from the crowd!"
			firstButton={{
				label: 'Become Sustain Planet Earth Committed® Now!',
				onClick: () => openInNewTab('https://spec.healrworld.com/'),
				size: 'm',
				variant: 'primary',
				backgroundColor: "#8acce2",
  				borderColor:"#8acce2",
  				fontColor:"#ffffff"
			}}
			illustration="https://dktjvr8ouliwm.cloudfront.net/images/spec-logo.png"
			title="Sustain Planet Earth Committed®"
			/></div>
		</div>
	  );
};

export default Marketplace1;
