/* this component is used to define the constant of all api endpoints */



export const API_CC_ENABLED = 'cc-enabled';
export const API_UPDATE_CC = 'update-cc';
export const API_UPDATE_ONBOARDING = 'update-onboarding';
export const API_ONBOARDING_SCREEN_SHOWN = 'onboarding-screen';
export const API_TUTORIAL_SCREEN = 'tutorial-screen';
export const API_UPDATE_TUTORIAL = 'update-tutorial';
export const API_CARBON_FOOTPRINT = 'Carbon-footprint';
export const API_ALL_TRANSACTION = 'all-transactions';
export const API_CATEGORIES_EXPLAINED = 'categories-explained';
export const API_TOP_CATEGORIES = 'top-categories';
export const API_TIP_CATEGORIES = 'tip-categorywise';
export const API_PRIVACY_POLICY = 'configuration/privacy-policy';
export const API_DONATE_OPTION = '/donate-now';


