/* This component is used for FIGMA SCREEN 16. for showing the Carbon Calculator Dashboard */


import {
  Button,
  Container,
  TabsContainer,
  Tab,
} from "@toqio/toqio-web-components";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { CategoryScale, LinearScale, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment/moment";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Header from "../../Components/Header";
import { Link } from "@toqio/toqio-web-components";
import { useTour } from "@reactour/tour";

import { BsCloudy } from "react-icons/bs";
import { GiPalmTree } from "react-icons/gi";


import {
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel";
import CategoryList from "../CategoryList";
import CategoryDetails from "../CategoryDetails";
import drawChart from "../../Components/drawChart";
import OurPartner from "../../Components/OurPartner";
import { getRequest } from "../../Services/RequestServices";
import {
  API_CARBON_FOOTPRINT,
  API_TUTORIAL_SCREEN,
} from "../../Services/ApiConstant";
import { setCookie } from "../../cookieHandler";
import { PRIMARY_COLOR } from "../../Constants/color";

ChartJS.register(ArcElement, Tooltip);
ChartJS.register(CategoryScale, LinearScale, BarElement);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const labels = ["Jan", "Feb", "Mar", "Apr"];

  const [tabIndex, setTabIndex] = useState(0);
  const { setIsOpen, currentStep, isOpen } = useTour();
  const ref = useRef(null);
  const [totalMonthlyFootprint, settotalMonthlyFootprint] = useState(0);
  const [totalMonthlyFootprintounces, settotalMonthlyFootprintounces] =
    useState(0);

  const sliderData = [
    {
      title: "What is the carbon calculator?",
      img: require("../../assets/onboarding/carbon_calculator.png"),
    },
    {
      title: "Why should I care about CO<sub>2</sub>?",
      img: require("../../assets/care_co2.png"),
    },
    {
      title: "How do i compare to others?",
      img: require("../../assets/compare.png"),
    },
    {
      title: "Why trees matter",
      img: require("../../assets/tree.png"),
    },
  ];

  const [barChartData, setBarChartData] = useState({
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [12, 19, 3, 5],
        backgroundColor: "#FB853C",
        borderRadius: 5,
      },
    ],
  });


/* it will call to change the date to next date */
  const onNext = () => {
    setDate(moment(date).add(1, "month"));
  };

  /* it will call to change the date to previous date */
  const onPrev = () => {
    setDate(moment(date).add(-1, "month"));
  };


/* it will call to first check isTour flag in localstorage and determine wheather tutorial screens are shown or not based if isTour flag is not set in localstorage then it will call the api and check the status and based on that we show the tutorial screen */
  useEffect(() => {
    const isTour = localStorage.getItem("isTour");
    if (isTour === "1") {
      setIsOpen(false);
    } else {
      getRequest(API_TUTORIAL_SCREEN).then((res) => {
        if (res?.data?.tutorial) {
          setIsOpen(false);
          localStorage.setItem("isTour", "1");
        } else {
          setIsOpen(true);
        }
      });
    }
  }, []);

  /* this function is used to create the slider item for learn more section */
  const renderItem = ({ title, desc, img }, index) => (
    <Container
      type="shadow"
      className={styles["crousel-item"]}
      onClick={() => navigate("/learn-more/details/" + (index + 2))}
    >
      <img src={img} className={styles["crousel-image"]} />
      <div className={styles["text-container"]}>
        <p
          className={styles["title"]}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </Container>
  );

  const [data3, setData3] = useState([
    {
      msmaincategoryname: "test",
      categoryIcon: "SlPlane",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "",
      categoryIcon: "HiOutlineBuildingLibrary",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "",
      categoryIcon: "IoGameControllerOutline",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "Lorenzo",
      categoryIcon: "AiOutlineCar",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "Hillary",
      categoryIcon: "TbHeartPlus",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "Jonathan",
      categoryIcon: "HiOutlineShoppingBag",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "Lorenzo",
      categoryIcon: "ImSpoonKnife",
      totalCarbonEmissionInGrams: 50,
    },
    {
      msmaincategoryname: "",
      categoryIcon: "VscHome",
      totalCarbonEmissionInGrams: 50,
    },
  ]);

  const categoryData = [
    {
      mainCategoryIcon: "IoGameControllerOutline",
      mainCategoryRef: "Leisure",
      maincategoryColor: "#2BAAB9",
      mainCategoryTitle: "Lesure & Entertainment",
      percentage: 3.0,
    },
    {
      mainCategoryIcon: "AiOutlineCar",
      mainCategoryRef: "transportation",
      maincategoryColor: "#9455BE",
      mainCategoryTitle: "Transportation",
      percentage: 1.0,
    },
    {
      mainCategoryIcon: "TbHeartPlus",
      mainCategoryRef: "health",
      maincategoryColor: "#FB853C",
      mainCategoryTitle: "Health & Beauty",
      percentage: 96.0,
    },
    {
      mainCategoryIcon: "HiOutlineBuildingLibrary",
      mainCategoryRef: "govt",
      maincategoryColor: "#C74765",
      mainCategoryTitle: "Government Services",
      percentage: 0,
    },
    {
      mainCategoryIcon: "HiOutlineShoppingBag",
      mainCategoryRef: "shopping",
      maincategoryColor: "#79B252",
      mainCategoryTitle: "Shopping",
      percentage: 0,
    },
    {
      mainCategoryIcon: "VscHome",
      mainCategoryRef: "home",
      maincategoryColor: "#FFA833",
      mainCategoryTitle: "Home & Garden",
      percentage: 0,
    },
    {
      mainCategoryIcon: "ImSpoonKnife",
      mainCategoryRef: "food",
      maincategoryColor: "#32C7D9",
      mainCategoryTitle: "Food & Beverages",
      percentage: 0,
    },
    {
      mainCategoryIcon: "SlPlane",
      mainCategoryRef: "Travel",
      maincategoryColor: "#BCBCBC",
      mainCategoryTitle: "Travel",
      percentage: 0,
    },
  ];

 
/* it will call to fetch the donut and bar chart's data based on selected date */
  useEffect(() => {
    getRequest(
      `${API_CARBON_FOOTPRINT}/${moment(date).format("MMMM")}/${moment(
        date
      ).format("YYYY")}`
    ).then((res) => {
      if (res.data) {
        settotalMonthlyFootprint(res?.data?.totalMonthlyFootprint);
        // setData3(res?.data)
        setCookie(
          "footprint",
          (res?.data?.totalMonthlyFootprint / 1000)?.toFixed(2)
        );
        settotalMonthlyFootprintounces(res?.data?.totalMonthlyFootprintounces);
        const barData = {
          labels: res.data?.co2permonth?.map((itm) => itm.month),
          datasets: [
            {
              label: "",
              data: res.data?.co2permonth?.map(
                (itm) => itm.totalMonthlyFootprint
              ),
              backgroundColor: "#FB853C",
              borderRadius: 5,
            },
          ],
        };

        setBarChartData(barData);
      }
    });

    localStorage.setItem("date", JSON.stringify(date));
  }, [date]);

  /* it will call to create the donut chart using common function called drawchart */

  useEffect(() => {
    if (ref.current && data3) {
      drawChart(ref.current, data3, "600000", navigate);
      // drawChart(ref.current, data3, totalMonthlyFootprint, navigate);
    }
  }, [ref, data3]);

  /* it will call only once while loading the component and set sctoll postition to the top */

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  /*it will call get dynamic height of learn more section slider */

  const getHeight = () => {
    let height = 0;
    if (window.screen.width > 450) {
      height = 100;
    } else if (window.screen.width > 350) {
      height = 115;
    } else {
      height = 115;
    }

    return height;
  };
  return [
    <>
      {(currentStep === 0 || !isOpen) && (
        <div className="res-container">
          <Header
            title={"Your carbon footprint"}
            hideBack={() => navigate("/")}
          />
          <div className={styles["container"]}>
            <Container
              style={{
                width: "100%",
                paddingBottom: 20,
                maxWidth: 600,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              type="shadow"
            >
              <div
                style={{
                  fontFamily: "Inter-Bold",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  position: "relative",
                }}
              >
                <div style={{ width: 40 }} className="row-center">
                  {moment(date).isAfter(moment("2018-05-01", "YYYY-MM-DD")) && (
                    <SlArrowLeft
                      onClick={() => onPrev()}
                      className="font-14 pointer"
                    />
                  )}
                </div>
                <div
                className="font-18"
                  style={{
                  
                    width: 160,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >{`${moment(date).format("MMMM YYYY")}`}</div>
                <div style={{ width: 40 }} className="row-center">
                  {moment(date).isBefore(
                    moment(new Date()).subtract("1", "months")
                  ) && (
                    <SlArrowRight
                      onClick={() => onNext()}
                      className="font-14 pointer"
                    />
                  )}
                </div>
              </div>

              <AiOutlineInfoCircle
                color="#89cce2"
                size={"28px"}
                className="pointer"
                style={{
                  alignSelf: "flex-end",
                  marginRight: 20,
                  marginTop: 20,
                  position: "relative",
                  zIndex: 10000,
                }}
                onClick={() => navigate("/dashboard/carbon-calculator")}
              />

              <div
                className="graph first-step"
                onClick={() => navigate("/category-list")}
                ref={ref}
              />

              <Button
                style={{
                  fontFamily: "Inter-Bold",
                  width: "80%",
                  margin: "30px auto",
                }}
                className="solid-btn font-16"
                onClick={() => navigate("/category-list")}
              >
                View emissions by category
              </Button>

              <div
                style={{
                  width: "70%",
                  margin: "20px auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: "#F0F0F0",
                    justifyContent: "center",
                    alignItems: "center",

                    display: "flex",
                  }}
                >
                  <BsCloudy size={25} style={{ marginBottom: 5 }} />
                </div>
                <div
                  style={{
                    fontFamily: "Inter-Bold",
                    width: 26,
                    height: 26,
                    borderRadius: 13,
                    backgroundColor: "#F0F0F0",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  =
                </div>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: "#F0F0F0",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <GiPalmTree size={25} />
                </div>
              </div>
              <div
                style={{
                  width: "66%",
                  margin: "-10px auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="column-center">
                  <div className="font-18" style={{ fontFamily: "Inter-Bold"}}>
                    {(totalMonthlyFootprint / 1000).toFixed(2)}
                  </div>
                  <div className="font-10"
                    style={{  marginTop: 4, textAlign: "center" }}
                  >
                    kg CO<sub>2</sub>*
                  </div>
                </div>
                <div className="column-center">
                  <div className="font-18" style={{ fontFamily: "Inter-Bold" }}>
                    {totalMonthlyFootprintounces}
                  </div>
                  <div className="font-10"
                    style={{  marginTop: 4, textAlign: "center" }}
                  >
                    Trees
                  </div>
                </div>
              </div>
              <div className="font-14"
                style={{ color: "#686868",   margin: "30px auto" }}
              >
                *CO<sub>2</sub> captured by trees over 5 years.
              </div>
              <div className="row-center">
                <Link onClick={() => navigate("/donation-option")}>
                  <div
                   className="font-14"
                    style={{
                      
                      fontFamily: "Inter-Medium",
                      fontWeight: 200,
                      margin: "0px auto",
                      marginBottom: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Plant a tree for as little as $2 <SlArrowRight />{" "}
                  </div>
                </Link>
              </div>
            </Container>

            <Container
              style={{
                width: "100%",
                maxWidth: 600,
                marginTop: 20,
                justifyContent: "center",
                display: "flex",
                // height:250,
                flexDirection: "column",
              }}
              type="shadow"
            >
              <p className="font-20"
                style={{
                 
                  fontFamily: "Inter-Bold",
                  textAlign: "center",
                }}
              >
                Your total donations
              </p>
              <div
                style={{
                  width: "70%",
                  margin: "20px auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: PRIMARY_COLOR,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <GiPalmTree size={25} />
                </div>

                <div
                  style={{
                    fontFamily: "Inter-Bold",
                    width: 26,
                    height: 26,
                    borderRadius: 13,
                    backgroundColor: "#F0F0F0",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  =
                </div>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: PRIMARY_COLOR,
                    justifyContent: "center",
                    alignItems: "center",

                    display: "flex",
                  }}
                >
                  <BsCloudy size={25} style={{ marginBottom: 5 }} />
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  margin: "-10px auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="column-center ml-1">
                  <div className="font-18" style={{ fontFamily: "Inter-Bold" }}>
                    {"32"}
                  </div>
                  <div className="font-10"
                    style={{ marginTop: 4, textAlign: "center" }}
                  >
                    Trees
                  </div>
                </div>

                <div className="column-center">
                  <div style={{ fontFamily: "Inter-Bold" }} className="font-18">
                    {"-640"}
                  </div>
                  <div className="font-10"
                    style={{ marginTop: 4, textAlign: "center" }}
                  >
                    kg CO<sub>2</sub>*
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <div className="divider"></div>
                <div className="p-20 row-center">
                  <Link
                    onClick={() => navigate("/learn-more/pricesless-project")}
                  >
                    View tree planting projects
                  </Link>
                </div>
              </div>
            </Container>
            <Container
              style={{
                width: "100%",
                maxWidth: 600,
                marginTop: 20,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              type="shadow"
            >
              <div className="row-between p-24">
                <div className="font-18" style={{ fontFamily: "Inter-Bold" }}>
                  My CO<sub>2</sub> per month
                </div>

                <AiOutlineInfoCircle
                  onClick={() => navigate("/co2pm")}
                  color="#89cce2"
                  size={"28px"}
                  className="pointer"
                />
              </div>
              <TabsContainer style={{ marginBottom: 40 }}>
                <Tab
                  onClick={() => setTabIndex(0)}
                  isActive={tabIndex === 0}
                  TabBottomSelectorLineStyle
                  label="Country average"
                ></Tab>
                <Tab
                  onClick={() => setTabIndex(1)}
                  isActive={tabIndex === 1}
                  label="United Nations goal"
                ></Tab>
              </TabsContainer>
              <Bar
                options={{
                  barPercentage: 0.6,
                  font: { size: 23 },
                  scales: {
                    y: {
                      ticks: {
                        display: false,
                      },

                      grid: {
                        display: false,
                        lineWidth: 0,
                        zeroLineColor: "transparent",
                      },
                      border: {
                        display: false,
                      },
                    },
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
                data={barChartData}
              />
              <div className="row-center">
                <Link onClick={() => navigate("/all-transactions")}>
                  <div className="font-16"
                    style={{
                     
                      margin: "30px auto",
                      marginBottom: 10,
                      fontFamily: "Inter-Medium",
                      fontWeight: 200,
                    }}
                  >
                    View transactions
                  </div>
                </Link>
              </div>
            </Container>
            <br />
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="font-20" style={{ fontFamily: "Inter-Bold" }}>
                Learn More
              </div>

              <Link onClick={() => navigate("/learn-more")}>
                <div className="font-16">View All</div>
              </Link>
            </div>
            <br />
            <br />
            <div className={styles["crousel-container"]}>
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={getHeight()}
                totalSlides={4}
                currentSlide={0}
                visibleSlides={
                  window.screen.width > 450
                    ? 2.5
                    : window.screen.width > 400
                    ? 2
                    : window.screen.width > 340
                    ? 1.75
                    : 1.45
                }
              >
                <Slider>
                  {sliderData.map((slideItem, index) => (
                    <Slide key={index.toString()} index={index}>
                      {renderItem(slideItem, index)}
                    </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
            </div>

            <div className="font-20"
              style={{
                width: "100%",
                margin: "0px auto",
               
                fontFamily: "Inter-Bold",
              }}
            >
              Take Action
            </div>
            <br />
            <br />
            <Container
              type="shadow"
              className={styles["action-card"]}
              onClick={() => navigate("/take-action")}
            >
              <div style={{ width: 60, display: "flex" }}>
                <img
                  src={require("../../assets/onboarding/Illustration.png")}
                  style={{ width: "100%", minHeight: 100, maxHeight: 140 }}
                />
              </div>
              <div style={{ padding: 10, width: "70%" }}>
                <div className="font-20"
                  style={{
                  
                    marginTop: 10,
                    fontFamily: "Inter-Bold",
                  }}
                >
                  Donate to plant trees
                </div>
                <div className="font-14" style={{ marginTop: 10 }}>
                  Help us reach our goal of planting 100 million trees
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#89cce2",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: 40,
                }}
              >
                <SlArrowRight
                  onClick={() => onNext()}
                  color="#fff"
                  className="font-14 pointer"
                
                />
              </div>
            </Container>
            <br />

            <Container
              type="shadow"
              className={styles["action-card"]}
              onClick={() => navigate("/quick-tips")}
            >
              <div style={{ width: 60, display: "flex" }}>
                <img
                  src={require("../../assets/Illustration.png")}
                  style={{ width: "100%", minHeight: 100, maxHeight: 140 }}
                />
              </div>
              <div style={{ padding: 10, width: "70%" }}>
                <div className="font-20"
                  style={{
                   
                    marginTop: 10,
                    fontFamily: "Inter-Bold",
                  }}
                >
                  Quick tips
                </div>
                <div className="font-14" style={{ marginTop: 10 }}>
                  Learn how to reduce your carbon footprint
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#89cce2",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: 40,
                }}
              >
                <SlArrowRight
                  onClick={() => onNext()}
                  color="#fff"
                  className="font-14 pointer"
                />
              </div>
            </Container>
            <br />
            <Container
              type="shadow"
              className={styles["action-card"]}
              onClick={() => navigate("/music4climate-impact")}
            >
              <div style={{ width: 60, display: "flex" }}>
                <img
                  src={require("../../assets/onboarding/Illustration.png")}
                  style={{ width: "100%", minHeight: 100, maxHeight: 140 }}
                />
              </div>
              <div style={{ padding: 10, width: "70%" }}>
                <div className="font-20"
                  style={{
                  
                    marginTop: 10,
                    fontFamily: "Inter-Bold",
                  }}
                >
                  The Music4Climate® Impact Fund (501c3)
                </div>
                <div className="font-14" style={{ marginTop: 10 }}>
                Donate now to support climate justice
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#89cce2",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: 40,
                }}
              >
                <SlArrowRight
                  onClick={() => onNext()}
                  color="#fff"
                  className="font-14 pointer"
                
                />
              </div>
            </Container>
            <br />
            <div className={styles["action-card"]}>
              <OurPartner />
            </div>
            <br />
          </div>
        </div>
      )}
    </>,

    <> {isOpen && currentStep === 1 && <CategoryList data={categoryData} />}</>,
    <>{isOpen && currentStep === 2 && <CategoryDetails />}</>,

    // <ErrorMessage title={'500'} desc={'Internal Server Error'}/>
  ];
};

export default Dashboard;
