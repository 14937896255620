/* this component is used to define the common api services to execute the CRUD operation */

import axios from 'axios';
import { getCookie } from '../cookieHandler';



const baseUrl = ' https://api-dev.toqio-integration-app.healrworld.co.uk'


/* this method is used to make the get request with accepting the endpoint in params */

 export const getRequest = (endpoint,isId) => {
   const id = getCookie('user') ? JSON.parse(getCookie('user'))?.id : ""
   const url = isId ?`${baseUrl}/${endpoint}`  : `${baseUrl}/customer/${id}/${endpoint}`;
   const headers = {
      "Content-Type": "application/json",
    };
   return axios.get(`${url}`,{headers})
    .then(res => res)
    .catch(e=> e)

 } 


 /* this method is used to make the post request with accepting the endpoint and body data in params */
 export const postRequest =async (endpoint,data) => {
   const id =  getCookie('user') ? JSON.parse(getCookie('user'))?.id : ""

    return axios.post(`${baseUrl}/customer/${id}/${endpoint}`,data)
     .then(res => {
      console.log(res)
     })
 
  } 


  /* this method is used to make the put request with accepting the endpoint in params */

  export const putRequest = async(endpoint) => {
    return axios.put(`${baseUrl}/${endpoint}`)
     .then(res => {
      console.log(res)
     })
 
  } 


  /* this method is used to make the patch request with accepting the endpoint in params */

  export const patchRequest = async(endpoint) => {
    return axios.patch(`${baseUrl}/${endpoint}`)
     .then(res => {
      console.log(res)
     })
 
  } 