/* This component is used for FIGMA SCREEN 40. to show the details of project we support */


import React, { useEffect } from "react";
import { Button, Container } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { FiTarget } from "react-icons/fi";
import { GiPalmTree } from "react-icons/gi";
import { BsCloudy } from "react-icons/bs";

const PriceslessProject = () => {
  const navigate = useNavigate();

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header title={"Projects we support"} hideBack={() => navigate("/")} />
      <div>
        <Container type="shadow" className={styles["top-card"]}>
          <div className="p-10">
            <p className={styles["subtitle"]}>Tree target</p>
            <div className={styles["heading"]}>100 Million</div>
          </div>
          <div className="p-10">
            <p className={styles["subtitle"]} style={{ width: 80 }}>
              <span className="font-15"
                style={{
                  fontFamily: "Inter-Bold",
                  lineHeight: 1.5,
                }}
              >
                18
              </span>{" "}
              projects worldwide
            </p>
          </div>
        </Container>
        <div className="row-center mt-2">
          <Button
            className={"solid-btn"}
            onClick={() => navigate("/donation-option")}
          >
            Donate to plant trees
          </Button>
        </div>

        <p className={styles["title"]}>Latin America</p>
        <Container type="shadow" style={{ overflow: "hidden" }}>
          <div className="row-center relative">
            <img
              src={require("../../assets/priceless/forest_new.jpg")}
              className={styles["img"]}
            />
            <div className={styles["text-overlay"]}>
              <img
                style={{ width: 20, height: 20 }}
                src={require("../../assets/priceless/icon.png")}
              />
              <p className={styles["subtitle"]}>Brazil</p>
              <p className="font-15"
                style={{
                  color: "#fff",
                  fontFamily: "Inter-Semibold",
                }}
              >
                Amazon and Atlantic Forests
              </p>
            </div>
          </div>
          <div className="p-10">
            <div className="font-17"
              style={{
                fontFamily: "Inter-SemiBold",
                
                lineHeight: 1.5,
                marginTop: 15,
              }}
            >
              Project goals
            </div>

            <div
              className="row-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="column-center m-1">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <FiTarget size={"28px"} color="#fff" />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  2,000,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Planting goal
                </div>
              </div>
              <div className="column-center m-1 text-center">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <BsCloudy
                    size={"28px"}
                    color="#fff"
                    style={{ marginTop: -6 }}
                  />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  40,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Captured tonnes CO<sub>2</sub>* goal
                </div>
              </div>
              <div className="column-center m-1 text-center">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <GiPalmTree size={"28px"} color="#000" />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  100,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Trees planted to date
                </div>
              </div>
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <p className="font-17"
              style={{
                fontFamily: "Inter-SemiBold",
           
              }}
            >
              Reforestation progress
            </p>
            <p className="font-12"
              style={{
                fontFamily: "Inter-SemiBold",
                
              }}
            >
              Your donations at work
            </p>
            <div className="row-center">
              <img
                src={require("../../assets/priceless/planting.jpg")}
                className={styles["img"]}
              />
            </div>
            <br />
            <div className={styles["desc"]}>
              <span style={{ fontFamily: "Inter-Bold" }}>
                Amazon and Atlantic Forests
              </span>
              <br />
              This summer, field teams in the Amazon prepared 30 hectares in the
              Xingu watershed to receive seeds and commence planting in the
              rainy season. Active consultations with landowners were
              fundamental in defining the area along the river as a priority
              area, suitable for the “muvuca” direct seeding approach.
            </div>
            <div
            className="font-14"
              style={{
                color: "#686868",
                textAlign: "center",
                 
                margin: "30px auto",
              }}
            >
              *CO<sub>2</sub> captured by trees over 5 years.
            </div>
          </div>
        </Container>

        <br />
        <Container type="shadow" style={{ overflow: "hidden" }}>
          <div className="row-center relative">
            <img
              src={require("../../assets/priceless/brazil.jpg")}
              className={styles["img"]}
            />
            <div className={styles["text-overlay"]}>
              <img
                style={{ width: 20, height: 20 }}
                src={require("../../assets/priceless/icon.png")}
              />
              <p className={styles["subtitle"]}>Brazil</p>
              <p className="font-15"
                style={{
                  color: "#fff",
                  fontFamily: "Inter-Semibold",
                }}
              >
                Abrolhos Land and Seascape
              </p>
            </div>
          </div>
          <div className="p-10">
            <div className="font-17"
              style={{
                fontFamily: "Inter-SemiBold",
               
                lineHeight: 1.5,
                marginTop: 15,
              }}
            >
              Project goals
            </div>

            <div
              className="row-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="column-center m-1">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <FiTarget size={"28px"} color="#fff" />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  2,000,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Planting goal
                </div>
              </div>
              <div className="column-center m-1 text-center">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <BsCloudy
                    style={{ marginTop: -6 }}
                    size={"28px"}
                    color="#fff"
                  />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  40,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Captured tonnes CO<sub>2</sub>* goal
                </div>
              </div>
              <div className="column-center m-1 text-center">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <GiPalmTree size={"28px"} color="#000" />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  100,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Trees planted to date
                </div>
              </div>
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <p className="font-17"
              style={{
                fontFamily: "Inter-SemiBold",
              
              }}
            >
              Reforestation progress
            </p>
            <p className="font-12"
              style={{
                fontFamily: "Inter-SemiBold",
                
              }}
            >
              Your donations at work
            </p>
            <div className="row-center">
              <img
                src={require("../../assets/priceless/growing.jpg")}
                className={styles["img"]}
              />
            </div>
            <br />
            <div className={styles["desc"]}>
              <span style={{ fontFamily: "Inter-Bold" }}>
                Abrolhos Land and Seascape
              </span>
              <br />
              This reforestation area is located in Brazil and situated in a
              global biodiversity hotspot. This site, the Atlantic Forest biome,
              is one of the most critical conservation areas in the world. It
              features 37 species of native trees.
            </div>
            <div
            className="font-14"
              style={{
                color: "#686868",
                textAlign: "center",
                 
                margin: "30px auto",
              }}
            >
              *CO<sub>2</sub> captured by trees over 5 years.
            </div>
          </div>
        </Container>

        <br />
        <Container type="shadow" style={{ overflow: "hidden" }}>
          <div className="row-center relative">
            <img
              src={require("../../assets/priceless/rowing.jpg")}
              className={styles["img"]}
            />
            <div className={styles["text-overlay"]}>
              <img
                style={{ width: 20, height: 20 }}
                src={require("../../assets/priceless/icon.png")}
              />
              <p className={styles["subtitle"]}>Mexico</p>
              <p className="font-15"
                style={{
                  color: "#fff",
                  fontFamily: "Inter-Semibold",
                }}
              >
                Oaxaca-Chiapas Coastal Landscapes
              </p>
            </div>
          </div>
          <div className="p-10">
            <div className="font-17"
              style={{
                fontFamily: "Inter-SemiBold",
            
                lineHeight: 1.5,
                marginTop: 15,
              }}
            >
              Project goals
            </div>

            <div
              className="row-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="column-center m-1">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <FiTarget size={"28px"} color="#fff" />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  2,000,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Planting goal
                </div>
              </div>
              <div className="column-center m-1 text-center">
                {/* <img
                  style={{ width: 40, height: 40, marginBottom: 5 }}
                  src={require("../../assets/priceless/target.png")}
                /> */}
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <BsCloudy
                    style={{ marginTop: -6 }}
                    size={"28px"}
                    color="#fff"
                  />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  40,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Captured tonnes CO<sub>2</sub>* goal
                </div>
              </div>
              <div className="column-center m-1 text-center">
                <div
                  style={{
                    backgroundColor: "#B5E3D8",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                  className="row-center"
                >
                  <GiPalmTree size={"28px"} color="#000" />
                </div>
                <div className="font-13"
                  style={{
                   
                    fontFamily: "Inter-SemiBold",
                    lineHeight: 1.5,
                  }}
                >
                  100,000
                </div>
                <div className="font-11" style={{ fontFamily: "Inter-Light" }}>
                  Trees planted to date
                </div>
              </div>
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <p className="font-17"
              style={{
                fontFamily: "Inter-SemiBold",
         
              }}
            >
              Reforestation progress
            </p>
            <p className="font-12"
              style={{
                fontFamily: "Inter-SemiBold",
              
              }}
            >
              Your donations at work
            </p>
            <div className="row-center">
              <img
                src={require("../../assets/priceless/caring.jpg")}
                className={styles["img"]}
              />
            </div>
            <br />
            <div className={styles["desc"]}>
              <span style={{ fontFamily: "Inter-Bold" }}>
                Oaxaca-Chiapas Coastal Landscapes
              </span>
              <br />
              This reforestation area is located in the southeast of Mexico. It
              contains 70% of the biodiversity of North America — ranking it
              among the five most important regions in the world for its
              mega-diversity. It features 27 species of native trees in its many
              forest types including: tropical, coniferous, mesophilic and
              mangroves.
            </div>
            <div
            className="font-14"
              style={{
                color: "#686868",
                textAlign: "center",
                 
                margin: "30px auto",
              }}
            >
              *CO<sub>2</sub> captured by trees over 5 years.
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PriceslessProject;
