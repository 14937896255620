/* This component is used for FIGMA SCREEN 18. for showing the per month country average */

import React, { useEffect } from "react";
import { Container, Link } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";

const Co2PM = () => {
  const navigate = useNavigate();

  const topCard = {
    img: require("../../assets/co2pm.png"),
  };

  const countryData = [
    {
      img: require("../../assets/flags/australia.png"),
      value: "1,281kg",
      progress: 95,
      name: "Australia",
    },
    {
      img: require("../../assets/flags/usa.png"),
      value: "1,281kg",
      progress: 85,
      name: "United States",
    },
    {
      img: require("../../assets/flags/canada.png"),
      value: "1,281kg",
      progress: 80,
      name: "Canada",
    },
    {
      img: require("../../assets/flags/japan.png"),
      value: "1,281kg",
      progress: 70,
      name: "Japan",
    },
    {
      img: require("../../assets/flags/germany.png"),
      value: "1,281kg",
      progress: 65,
      name: "Germany",
    },
    {
      img: require("../../assets/flags/sa.png"),
      value: "1,281kg",
      progress: 60,
      name: "South Africa",
    },
    {
      img: require("../../assets/flags/china.png"),
      value: "1,281kg",
      progress: 50,
      name: "China",
    },
    {
      img: require("../../assets/flags/uk.png"),
      value: "1,281kg",
      progress: 40,
      name: "United Kingdom",
    },
    {
      img: require("../../assets/flags/sweden.png"),
      value: "1,281kg",
      progress: 30,
      name: "Sweden",
    },
    {
      img: require("../../assets/flags/mexico.png"),
      value: "1,281kg",
      progress: 20,
      name: "Mexico",
    },
    {
      img: require("../../assets/flags/brazil.png"),
      value: "1,281kg",
      progress: 15,
      name: "Brazil",
    },
    {
      img: require("../../assets/flags/india.png"),
      value: "1,281kg",
      progress: 10,
      name: "India",
    },
  ];

  /* it will call only once while loading the component and set sctoll postition to the top */
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header
        title={"CO2 per month country average"}
        hideBack={() => navigate("/")}
      />
      <Container type="shadow" className={styles["container"]}>
        <img src={topCard.img} className={styles["img"]} />
        <div className="p-20 ">
          <p className={styles["title"]}>
            Australian monthly average
            <br />
            1,281 kg CO<sub>2</sub>
          </p>
          <p className={`${styles["desc"]} text-center`}>
            Curious to see how your carbon footprint compares to others in your
            country and around the world?
          </p>
          <p className={styles["subtitle"]}>Monthly emissions per person</p>
          <div>
            {countryData?.map((itm) => (
              <div
                className="row-center mt-1"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="font-14"
                  style={{ textAlign: "right", width: 150 }}
                >
                  {itm.name}
                </div>
                <img
                  style={{ width: 20, marginLeft: 10, marginRight: 10 }}
                  src={itm.img}
                />
                <div style={{ width: "60%" }}>
                  <div
                    style={{
                      width: `${itm.progress}%`,
                      height: 14,
                      borderEndEndRadius: 3,
                      backgroundColor: "#BCBCBC",
                      borderTopRightRadius: 3,
                    }}
                  ></div>
                </div>
                <div className="font-14">{itm.value}</div>
              </div>
            ))}
          </div>
          <div>
            <div className={styles["sub-desc"]}>
              kg = kilograms of CO<sub>2</sub> per person
            </div>
            <div>Source: Our World In Data (2020)</div>
          </div>
        </div>
      </Container>
      <Container type="shadow" className={styles["container"]}>
        <div
          className={styles["img"]}
          style={{
            background: "#252324",
            height: 180,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/united.png")}
            style={{ width: "80%" }}
          />
        </div>
        <div className="p-20 ">
          <p className={styles["title"]}>United Nations 2030 goal</p>
          <p className={styles["desc"]}>
            Did you know the United Nations established its own climate action
            goal? To combat the rate at which our planet warms, the United
            Nations is focused on reducing worldwide carbon emissions.
          </p>
          <img
            src={require("../../assets/goals.png")}
            className={styles["img"]}
          />
          <p className={styles["desc"]}>
            Even if your country's emissions are below the national average, you
            can help the planet by understanding your own impact and taking
            meaningful action to manage your personal carbon footprint.
            <br />
            <br />
            We highlight the United Nations goal to support this larger global
            initiative focused on creating a healthier planet for us all.
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 4 }}>Source:</div>{" "}
            <Link>United Nations</Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Co2PM;
