/* This component is used for FIGMA SCREEN 41. to show the list of quick tips item  */

import React, { useState,useEffect } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Container, Link, Spinner } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import { SlArrowRight } from "react-icons/sl";

import { getRequest } from "../../Services/RequestServices";
import { API_CATEGORIES_EXPLAINED } from "../../Services/ApiConstant";
import { getIcon } from "../../Constants/icons";


const QuickTips = () => {
  const navigate = useNavigate();

  const [categoreis,setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  /* this function will used to get all categories list for quick tips section */
  useEffect(()=>{
    getRequest("customer/"+API_CATEGORIES_EXPLAINED,true).then((res)=>{
      setCategories(res?.data?.subcategory)
      setIsLoading(false);
    })
  },[])
  
  
  /* it will call only once while loading the component and set sctoll postition to the top */

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0, 0)
    },10)
  

},[])

  return (
    <div className="res-container">
      <Header
        title={"Quick tips"}
        hideBack={() => navigate("/")}
      />
     <div  style={{background: "#F8F8F8"}}>
      <Container type="shadow"   style={{width:'100%',maxWidth:600, paddingTop:10, margin: "0px auto" }}>
        <p className="font-18" style={{ marginLeft:50,marginRight:50,fontFamily:'Inter-SemiBold',textAlign:'center',marginBottom:30}}>Learn easy ways to reduce your carbon footprint </p>
       {!isLoading ? (
       <>
        {categoreis?.map((itm) => (
          <div onClick={()=> navigate('/quick-tips-details/'+itm.mcmainreftitle)} style={{paddingLeft:20,borderLeft:"5px solid "+itm.maincategorycolor}} className
          ={"second-step "+styles["container"]}>
             {getIcon(itm.mainCategoryIcon,itm?.maincategorycolor,'30px')}
            <div className="w-90 ml-2">
              <div className="row-between" style={{alignItems:'center'}}>
                <div className={styles['title']}>{itm.maincategorytitle}</div>
                <SlArrowRight color="#686868" className="font-14" style={{marginRight:20,   cursor: "pointer" }} />
              </div>
             
            </div>
          </div>
        ))}

        </>

):<div className="row-center w-100" style={{ height: "20vh" }}>
{" "}
<Spinner />
</div>}



      
      </Container>

      <p className="font-20"  style={{fontFamily:'Inter-Bold',marginTop:30}}>Other ways to help</p>

          <Link onClick={()=>navigate('/take-action')}>
            <div className="font-14" style={{  display:'flex',alignItems:'center' }}>
            Donate to plant trees <SlArrowRight />{" "}
            </div>
          </Link>
      </div>
    </div>
  );
};

export default QuickTips;
