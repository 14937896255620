/* This component is used for FIGMA SCREEN 12.1. to show the list of settings item on clicking of 3 dots from header  */

import React, { useState } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Button, Container, Modal } from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import { SlArrowRight } from "react-icons/sl";
import { TfiClose } from "react-icons/tfi";
import { AiOutlineFileText } from "react-icons/ai";
import { PRIMARY_COLOR } from "../../Constants/color";
import { API_UPDATE_CC } from "../../Services/ApiConstant";
import { postRequest } from "../../Services/RequestServices";
import { setCookie } from "../../cookieHandler";

const Settings = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState();

  const categoreis = [
    {
      name: "Privacy notice",
      navigate: "/privacy-policy",
      color: "#32C7D9",
      img: <AiOutlineFileText size={"35px"} color={PRIMARY_COLOR} />,
      value: 22,
    },
    {
      name: "Terms & Conditions",
      navigate: "/terms-condition",
      color: " #79B252",
      img: <AiOutlineFileText size={"35px"} color={PRIMARY_COLOR} />,
      value: 17,
    },
  ];

  const closeItem = {
    name: "Opt-out of Carbon Calculator",
    color: "#FB853C",
    img: <TfiClose size={"25px"} color={PRIMARY_COLOR} />,
    value: 9,
  };

  /* this function will use to update ccEnabled as false so the user will get opt-out from the application */
  const onUpdateCC = () => {
    postRequest(API_UPDATE_CC, {
      ccEnabled: false,
    }).then((res) => {
      const status = { ccenabled: false, onboarding: true };
      setCookie("status", JSON.stringify(status));
      window.location.href = "/";
    });
  };

  return (
    <div className="res-container opt-out">
      <Header title={"Carbon Calculator"} hideBack={() => navigate("/")} />

      <Container
        type="shadow"
        style={{
          width: "100%",
          maxWidth: 600,
          paddingTop: 10,
          margin: "20px auto",
        }}
      >
        {categoreis.map((itm) => (
          <div
            onClick={() => navigate(itm.navigate)}
            type="shadow"
            className={styles["container"]}
          >
            <div className="row-between w-100 pointer">
              <div style={{ display: "flex", alignItems: "center" }}>
                {itm.img}
                <div className={styles["title"]}>{itm.name}</div>
              </div>
              <SlArrowRight
                className="font-14"
                color="#686868"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        ))}
      </Container>

      <Container
        type="shadow"
        style={{
          width: "100%",
          maxWidth: 600,
          paddingTop: 10,
          margin: "40px auto",
        }}
      >
        <div
          onClick={() => setIsOpen(true)}
          type="shadow"
          className={styles["container"]}
        >
          <div className="row-between pointer w-100">
            <div style={{ display: "flex", alignItems: "center" }}>
              {closeItem.img}
              <div className={styles["title"]}>{closeItem.name}</div>
            </div>
            <SlArrowRight
              className="font-14"
              color="#686868"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </Container>

      <div className="row-center">
        <Modal
          close={() => setIsOpen(false)}
          size="s"
          visible={isOpen}
          contentClassName="modal"
        >
          <div
            style={{
              width: "90%",
              margin: "0px auto",
              backgroundColor: "#fff",
            }}
          >
            <div className={styles["title-head"]}>
              Are you sure you want to Opt-out of Carbon Calculator?
            </div>
            <br />
            <div
              style={{
                display: "flex",
                marginTop: 20,
                marginBottom: 20,
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button onClick={() => onUpdateCC()}>Yes</Button>

              <Button
                onClick={() => setIsOpen(false)}
                className="ml-2"
                style={{
                  backgroundColor: "#fff",
                  borderColor: PRIMARY_COLOR,
                  color: PRIMARY_COLOR,
                }}
              >
                <span style={{ color: PRIMARY_COLOR }}>No</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Settings;
