/* This component is used for FIGMA SCREEN 08. to show the Thank you page after completing the onboarding step */



import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import styles from "./styles.module.css";

const ThankYou = () => {
  const navigate = useNavigate();

  return [
    <div className={styles["res-container"]}>
    <div className={styles["container"]}>
        <img src={require('../../assets/onboarding/thankyou.png')} className={styles['img']}/>
         <div className={styles['text-container']} >
        <p className={styles['title']}>{'Thank you'}</p>
        <div className={styles['desc']}>{"You've taken the first step to combat climate change with Partnerbank."}</div>
        <p className={styles['desc']} >{"Now we've got to crunch some numbers.Your carbon footprint will be ready within 24 hours."}</p>
        <Button style={{width:'10%'}} className='solid-btn mt-2' onClick={()=>navigate('/dashboard')}>OK</Button>
      
        </div> 
        
    </div>
    </div>
  ];
};

export default ThankYou;
