/* this component is used to show the error page UI like if we get the error from api request then this component will call and show the error code */


import React from 'react';
import {
	EmptyState,
	spacing
} from '@toqio/toqio-web-components';

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const ErrorMessage = () => {
	return (
		<div className="app-container" style={ spacing('m', 5, true) }>
			<div style={{
        display: 'flex ',
        justifyContent: 'space-around',
		textAlign: 'center'
      }}>
		  <EmptyState
			description="Oops, due to some technical issue, we are not able get your user information. Please check back after some time."
			illustration={require("../../src/assets/error_icon.png")} 
			title="Communication Error"
			/></div>
		</div>
	  );
};

export default ErrorMessage;

