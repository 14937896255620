/* This is the common component that is used for FIGMA SCREEN 05,17 and 35. for Showing the brief of Carbon Calculator */


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import OnboardHeader from "../../Components/OnboardHeader";
import { getCookie } from "../../cookieHandler";
import {
  API_CC_ENABLED,
  API_ONBOARDING_SCREEN_SHOWN,
} from "../../Services/ApiConstant";
import { getRequest } from "../../Services/RequestServices";
import styles from "./styles.module.css";

const CarbonCalculator = (props) => {
  const navigate = useNavigate();
  const [ccenabled, setCCEnabled] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(true);

  /* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  /* it will call on loading of component to check wheather the ccenable and onboarding are enable or not */
  useEffect(() => {
    const status = getCookie("status");

    if (status) {
      const { ccenabled, onboarding } = JSON.parse(status);
      setCCEnabled(ccenabled);
      setIsOnboarding(onboarding);
    } else {
      getRequest(API_CC_ENABLED).then((res) => {
        setCCEnabled(res?.data?.ccenabled);
        getRequest(API_ONBOARDING_SCREEN_SHOWN).then((resp) => {
          setIsOnboarding(resp?.data?.onboarding);
        });
      });
    }
  }, []);
  return [
    <div className={!props.hideButton ? "cc-res-container" : null}>
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "-20px auto",
          background: "#fff",
        }}
      >
        <>
          {!props.hideButton && (
            <OnboardHeader
              style={{ position: "absolute", top: 10 }}
              hideBack={() => navigate(-1)}
            />
          )}
        </>

        <img
          style={{ width: "100%", height: "25%" }}
          src={require("../../assets/onboarding/carbon_calculator.png")}
        />
        <div className={styles["text-container"]}>
          <p className={styles["title"]}>{"What is the Carbon Calculator?"}</p>
          <p className={styles["desc"]}>
            {
              "This service measures your estimated carbon footprint based on the transactions you make using your linked Partnerbank debit or credit card."
            }
          </p>
        </div>
        <div className={styles["text-container"]}>
          <p className={styles["title"]}>
            {"How is my estimated footprint calculated?"}
          </p>
          <p className={styles["desc"]}>
            For your privacy, we cannot see what you buy. Instead, we estimate
            the CO<sub>2</sub> impact of your transactions based on the{" "}
            <strong>type of store</strong> where you shop, like a grocery store,
            bakery or restaurant, and how much you spend.
          </p>
        </div>
        <div className={styles["text-container"]}>
          <p className={styles["title"]}>{"The formula"}</p>
          <div className={styles["table"]}>
            <div className={styles["header"]}>
              <span className={styles["header-title"]}>{"Type of store"}</span>
              <span className={styles["header-title"]}>X</span>
              <span className={styles["header-title"]}>Spent</span>
              <span className={styles["header-title"]}>=</span>
              <span className={styles["header-title"]}>
                {" "}
                My CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["table-row"]}>
              <span className={styles["table-row-item"]}>{"Restaurant"}</span>
              <span className={styles["table-row-item"]}>X</span>
              <span className={styles["table-row-item"]}>$</span>
              <span className={styles["table-row-item"]}>=</span>
              <span className={styles["table-row-item"]}>
                {" "}
                kg CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["divider"]} />
            <div className={styles["table-row"]}>
              <span className={styles["table-row-item"]}>{"Bookstores"}</span>
              <span className={styles["table-row-item"]}>X</span>
              <span className={styles["table-row-item"]}>$</span>
              <span className={styles["table-row-item"]}>=</span>
              <span className={styles["table-row-item"]}>
                {" "}
                kg CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["divider"]} />

            <div className={styles["table-row"]}>
              <span className={styles["table-row-item"]}>
                {"Fuel stations"}
              </span>
              <span className={styles["table-row-item"]}>X</span>
              <span className={styles["table-row-item"]}>$</span>
              <span className={styles["table-row-item"]}>=</span>
              <span className={styles["table-row-item"]}>
                {" "}
                kg CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["divider"]} />
          </div>
          <p className={styles["desc"]}>
            Calculations are made using an independent service, the{" "}
            <span className="primary-color">Åland Index</span>, the world's
            leading climate impact index for CO<sub>2</sub> emission
            calculations. We only share the data needed to calculate your
            footprint. Your information is never shared or used for any other
            purpose.
          </p>
          <p className={styles["desc"]}>
            Each type of store is assigned an emissions factor calculated by the
            Åland Index. The type of store takes into account the products and
            items sold at each store type. There are different levels of carbon
            emissions associated with each type of store. For example, a fuel
            station will have a higher emissions factor than a cafe.
          </p>
        </div>
        <div className={styles["text-container"]}>
          <p className={styles["title"]}>{"Calculation examples"}</p>
          <div className={styles["table"]}>
            <div className={styles["header"]}>
              <span className={styles["header-title"]}>{"Type of store"}</span>
              <span
                className={styles["header-title"]}
                style={{ color: "#18A886" }}
              >
                X
              </span>
              <span className={styles["header-title"]}>Spent</span>
              <span
                className={styles["header-title"]}
                style={{ color: "#18A886" }}
              >
                =
              </span>
              <span className={styles["header-title"]}>
                {" "}
                My CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["table-row"]}>
              <span
                className={styles["table-row-item"]}
                style={{ lineHeight: 1.5 }}
              >
                <div>{"Cafe"}</div>
                <div>
                  <span style={{ color: "#18A886" }}>x</span>
                  {" 0.29"}
                </div>
                <div className="font-11">{"emissions factor"}</div>
              </span>
              <span
                className={styles["table-row-item"]}
                style={{ color: "#18A886" }}
              >
                X
              </span>
              <span className={styles["table-row-item"]}>$20</span>
              <span
                className={styles["table-row-item"]}
                style={{ color: "#18A886" }}
              >
                =
              </span>
              <span className={styles["table-row-item"]}>
                {" "}
                5.8kg CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["divider"]} />
            <div className={styles["table-row"]}>
              <span
                className={styles["table-row-item"]}
                style={{ lineHeight: 1.5 }}
              >
                <div>{"Bookstore"}</div>
                <div>
                  <span style={{ color: "#18A886" }}>x</span>
                  {" 0.15"}
                </div>
                <div className="font-11">{"emissions factor"}</div>
              </span>
              <span
                className={styles["table-row-item"]}
                style={{ color: "#18A886" }}
              >
                X
              </span>
              <span className={styles["table-row-item"]}>$20</span>
              <span
                className={styles["table-row-item"]}
                style={{ color: "#18A886" }}
              >
                =
              </span>
              <span className={styles["table-row-item"]}>
                {" "}
                3kg CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["divider"]} />

            <div className={styles["table-row"]}>
              <span
                className={styles["table-row-item"]}
                style={{ lineHeight: 1.5 }}
              >
                <div>{"Fuel station"}</div>
                <div>
                  <span style={{ color: "#18A886" }}>x</span>
                  {" 0.50"}
                </div>
                <div className="font-11">{"emissions factor"}</div>
              </span>
              <span
                className={styles["table-row-item"]}
                style={{ color: "#18A886" }}
              >
                X
              </span>
              <span className={styles["table-row-item"]}>$20</span>
              <span
                className={styles["table-row-item"]}
                style={{ color: "#18A886" }}
              >
                =
              </span>
              <span className={styles["table-row-item"]}>
                {" "}
                10kg CO<sub>2</sub>
              </span>
            </div>
            <div className={styles["divider"]} />
          </div>
          <p className={styles["desc"]} style={{ marginBottom: 10 }}>
            {
              "Enabling the Carbon Calculator tool is just one small step you can take to understand your carbon impact and live greener."
            }
          </p>
          <p className={`${styles["desc"]}`}>
            {
              "The Carbon Calculator will evolve as we make enhancements and further develop the tool. But this is a step in the right direction to protect our planet. Thank you for joining us on this important journey."
            }
          </p>
        </div>
        {(!isOnboarding || !ccenabled) && (
          <div className={styles["button-container"]}>
            <Button className={"outline-btn"} onClick={() => navigate("/")}>
              <div style={{ width: "50%" }}></div>Back
            </Button>
            <Button
              className={"solid-btn"}
              onClick={() => navigate("/privacy-notice")}
            >
              Get Started
            </Button>
          </div>
        )}
      </div>
    </div>,
  ];
};

export default CarbonCalculator;
