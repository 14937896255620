/* This component is used for FIGMA SCREEN 39. to show the details of priceless planet coalition */


import React, { useEffect } from "react";
import {
  Container,
} from "@toqio/toqio-web-components";
import styles from "./styles.module.css";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { GiLeafSwirl, GiMapleLeaf, GiPiggyBank } from "react-icons/gi";
import { IoLeafOutline } from "react-icons/io5";
import { PRIMARY_COLOR } from "../../Constants/color";
import { TbTrees } from "react-icons/tb";

const PriceLessDetails = () => {
  const topCard = {
    img: require("../../assets/priceless/close_up_hands.jpg"),
  };

  const navigate = useNavigate();


/* it will call only once while loading the component and set sctoll postition to the top */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <div className="res-container">
      <Header
        title={"Priceless Planet Coalition"}
        hideBack={() => navigate("/")}
      />
      <Container type="shadow" className={styles["container"]}>
        <img src={topCard.img} className={"w-100"} />
        <div className={styles["grey-box"]}>
          <p className={styles["title"]}>
            Restoring forests in Earth's most vulnerable geographies
          </p>
          <p className={styles["desc"]}>
            Human activity increasingly puts our planet’s most essential forests
            at risk. Our goal of planting 100 million trees is designed to
            combat this urgent and very real threat.
          </p>
        </div>
        <div className="p-20">
          <p className={styles["title"]}>Why trees?</p>
          <div className={styles["subtitle"]}>
            Restoring is a simple and powerful way of capturing CO<sub>2</sub>{" "}
            and mitigating climate change.
          </div>
          <br />
          <div className="row-center">
            <GiLeafSwirl size={50} color={PRIMARY_COLOR} />
          </div>
          <br />
          <div className={styles["desc"]}>
            Forests regulate the world’s oxygen and carbon cycles and control
            local and regional climates by exchanging water and energy with the
            atmosphere.
          </div>

          <br />
          <div className="row-center">
            <TbTrees size={50} color={PRIMARY_COLOR} />
          </div>
          <br />
          <div className={styles["desc"]}>
            Restoring the planet's forests is a cost-efficient and highly
            effective solution for mitigating the impact of global greenhouse
            gas emissions.
          </div>

          <br />
          <div className="row-center">
            <GiMapleLeaf size={50} color={PRIMARY_COLOR} />
          </div>
          <br />
          <div className={styles["desc"]}>
            Given the enormous amount of CO<sub>2</sub> stored by trees and the
            continuing threat of deforestation in Earth’s most vulnerable areas,
            there is tremendous risk if forest protection and restoration is not
            made a global priority.
          </div>

          <br />
          <div className="row-center">
            <IoLeafOutline size={50} color={PRIMARY_COLOR} />
          </div>
          <br />
          <div className={styles["desc"]}>
            Sustainable forest restoration also helps reduce the occurrence of
            climate-related disasters, such as fires and flooding.
          </div>

          <br />
          <div className="row-center">
            <GiPiggyBank size={50} color={PRIMARY_COLOR} />
          </div>
          <br />
          <div className={styles["desc"]}>
            Growing trees brings economic value to local people: Up to $30 for
            every $1 invested in restoring land.
          </div>
          <br />
          <div className="row-center">
            <img
              src={require("../../assets/priceless/forest.jpg")}
              className={"w-100"}
            />
          </div>
          <p className={styles["title"]}>
            Focusing on areas of greatest global need
          </p>
          <div className={styles["desc"]}>
            The Priceless Planet Coalition will employ a forest restoration
            model dedicated not only to the planting of 100 million trees but
            also to the regrowing of forests in geographies that represent the
            greatest global need. Areas with the greatest potential for positive
            impacts on climate, community and biodiversity goals will be
            prioritized as we set out to restore forestland across the globe.
          </div>
          <br />
          <div className={styles["desc"]}>
            Guided by Conservation International and World Resources Institute,
            we will employ science-based best practices for the selection,
            implementation and long-term monitoring of our restoration efforts.
            It is this approach that we believe sets the Priceless Planet
            Coalition apart, both in its sheer scale and in the quality of the
            work we plan to undertake.
          </div>
          <p className={styles["title"]}>
            A network of experts committed to our planet
          </p>

          <div className={styles["desc"]}>
            Mastercard has selected the global environmental organizations
            Conservation International and World Resources Institute to lead the
            mobilization and coordination of our restoration efforts,
            collaborating with local communities and stakeholders for long-term
            forest stewardship.
          </div>
          <br />
          <div
            className="row-center"
            style={{ background: "#F4F3F0", height: 200 }}
          >
            <img
              src={require("../../assets/ci.png")}
              className={styles["img"]}
            />
          </div>
          <p className={styles["title"]}>Conservation International</p>

          <div className={styles["desc"]}>
            Conservation International works to spotlight and secure the
            critical benefits that nature provides to humanity. Since their
            inception, they’ve helped to protect more than 6 million square
            kilometers (2.3 million square miles) of land and sea across more
            than 70 countries. Currently with offices in 29 countries and 2,000
            partners worldwide, their reach is truly global.
          </div>
          <br />
          <div
            className="row-center"
            style={{ background: "#F4F3F0", height: 200 }}
          >
            <img
              src={require("../../assets/wri.png")}
              className={styles["img"]}
            />
          </div>
          <p className={styles["title"]}>World Resources Institute</p>

          <div className={styles["desc"]}>
            WRI is a global research organization that spans more than 60
            countries with offices in the United States, China, India, Brazil,
            Indonesia and more. Their more than 1,000 experts and staff work
            closely with leaders to turn big ideas into action to sustain our
            natural resources – the foundation of economic opportunity and human
            well-being.
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PriceLessDetails;
